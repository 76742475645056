import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CompanyService } from '../../../services/company.service';
import { DataService } from '../../../services/data.service';
import { MenuService } from '../../../services/menu.service';
import { Imenu01Response } from '../../Interfaces/imenu01';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  color: string;
  cols: number;
  rows: number;
  class: string;
}

//export const ROUTES: RouteInfo[] = [];

@Component({
  selector: 'compmore-data',
  templateUrl: './compmore.component.html',
    styleUrls: ['./compmore.component.css']
})

export class CompmoreComponent {
  constructor(
    private dataService: DataService,
    private router: Router,
    private companyservice: CompanyService,
    private menuService:MenuService
  ) { }

  menuItems: any[];
  functions: RouteInfo[];


  async ngOnInit() {
    //relase company service data in order to those reports get the company default (my company)
    //const url = environment.urlAPI + 'api/menu/n2/';
    const userId = localStorage.getItem('userId');
    let ROUTES = [];
    //ROUTES.pop();
    //this.functions = [];
    this.functions = ROUTES;
    this.menuItems = [];
    this.menuService.getOptionsLevel2(parseInt( userId), 'COMPMORE').subscribe(results => {
      for (const entry of results.body.resultData) {
        this.addRoutes({
          path: '/' + entry.link.trim().toLowerCase(), title: entry.description.trim(),
          icon: entry.icon, color: '#A51955', cols: 1, rows: 1, class: entry.function_class, backColor:entry.backColor
        });
      }
      this.menuItems = this.functions.filter(menuItem => menuItem);
    },
      (e) => {
        console.log(e);
      });
    this.menuItems = this.functions.filter(menuItem => menuItem);


  }

  addRoutes(route) {
    this.functions.push(route);
  }

  b_back() {
    this.router.navigate(['company']);
  }

}
