import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
//import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TransactionService } from '../../services/transaction.service';
//import { DataTableService } from '../../../services/dataTable.service';
//import { ProviderService } from '../../../services/provider.service';
//import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../error-state-matcher1';
//import { IdatatableResponse } from '../../Interfaces/idatatable';
//import { IProviderRegistry } from '../../Interfaces/iprovider';
import { IAddTransaction, listTrnStage, listTrnStatus, listTrnTechProvider, trnStage, trnStatus, trnTechProvider } from '../Interfaces/itransaction';

@Component({
  selector: 'app-transactionadd',
  templateUrl: './transactionadd.component.html',
})

export class TransactionAddComponent implements OnInit {
  formedit: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  trnCode: string = "";
  ccId: number;
  //persontypelist: IdatatableResponse[];
  //departmentlist: IdatatableResponse[];
  //countrylist: IdatatableResponse[];
  //citylist: IdatatableResponse[];
  //citylistfilter: IdatatableResponse[];
  //taxresponsibilitieslist: IdatatableResponse[];
  //taxschemelist:IdatatableResponse[]

  constructor(
    private formBuilder: FormBuilder,
    private securityParams: ActivatedRoute, 
    private router: Router,
    private trnService: TransactionService,
    //private datatableservice: DataTableService,
    private dialogRef: MatDialogRef<TransactionAddComponent>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data:any
  )
  {
    this.formedit = formBuilder.group({
      prefix: ['', [Validators.required, Validators.maxLength(10)]],
      authorization: ['', [Validators.required, Validators.maxLength(30)]],
      numberlimitlower: [1, [Validators.required]],
      numberlimitupper: [2, [Validators.required]],
      number: [1, [Validators.required]],
      datelimitlower: [Date.now, [Validators.required]],
      datelimitupper: [Date.now, [Validators.required]],
      text01: [''],
      key01: ['', Validators.required],
      imc: [false],
      techprovider: [1, [Validators.required]],
      stage: [1, [Validators.required]],
      status: [1, [Validators.required]]
    });
  }
  selectedvalue: number; selectedview: string;
  listStatus: listTrnStatus[] = [{ code: 1, name: 'Activo' }, { code: 2, name: 'Inactivo' }];
  listStage: listTrnStage[] = [{ code: 1, name: 'NA' }, { code: 2, name: 'Pruebas' }, { code: 3, name: 'Habilitación' }, { code: 4, name: 'Producción' }]; /*, { code:3, name: 'No aplica' }*/
  listTechProvider: listTrnTechProvider[] = [{ code: 1, name: 'NA' }, { code: 2, name: 'GSIGMA-SPH' }, { code: 3, name: 'GSIGMA-F1' }];


  ngOnInit() {
    this.ccId = this.data.ccid; /* parseInt(this.securityParams.snapshot.params['ccid']);*/
    this.trnCode = this.data.trncode; /* this.securityParams.snapshot.params['trncode'].Trim();*/
    ////Department list
    //this.departmentlist = [];
    //this.datatableservice.getDataList("DEPTO").subscribe(results => {
    //  if (results.ok) {
    //    for (const entry of results.body) {
    //      this.departmentlist.push(entry);
    //    }
    //  }
    //}, (e) => {
    //  console.log(e);
    //});
    //Country list
    //this.countrylist = [];
    //this.datatableservice.getDataList("CNTRY").subscribe(results => {
    //  if (results.ok) {
    //    for (const entry of results.body) {
    //      this.countrylist.push(entry);
    //    }
    //  }
    //}, (e) => {
    //  console.log(e);
    //});

    this.mensaje = ""; 
  }

  Cancelar() {
    this.dialogRef.close();
  }

  Save()
  {
    try {
      let _Status = (this.formedit.value.status == 1 ? trnStatus.ACTIVE : trnStatus.INACTIVE);
      let _Stage = (this.formedit.value.stage == 1 ? trnStage.NA : this.formedit.value.stage == 2 ? trnStage.TESTING : this.formedit.value.stage == 3 ? trnStage.ENABLING : trnStage.PRODUCTION);
      let _TechProvider = (this.formedit.value.techprovider == 1 ? trnTechProvider.NA : this.formedit.value.techprovider == 2 ? trnTechProvider.SPH : trnTechProvider.F1);
      const IData: IAddTransaction =
      {
        authorization:this.formedit.value.authorization, stage:_Stage,
        ccId: this.ccId, code: this.trnCode, dateLimitLower: this.formedit.value.datelimitlower, dateLimitUpper: this.formedit.value.datelimitupper,
        copies: 1, status:_Status, prefix: this.formedit.value.prefix, imc: this.formedit.value.imc, number: this.formedit.value.number,
        numberLimitLower: this.formedit.value.numberlimitlower, numberLimitUpper: this.formedit.value.numberlimitupper,
        key01: this.formedit.value.key01, key02: "", key03: "", text01: this.formedit.value.text01, text02: "", text03: "",
        techProvider:_TechProvider
      };
      this.trnService.AddTransaction(IData).subscribe(results => {
        if (results.body.isValid)
        {
          this.openSB("Transaccion grabada");
          this.dialogRef.close(true);
        }
        else
        {
          alert(results.body.error.description);
        }
      },
        (e) => {
          this.mensaje = e.error;
          alert(e.error);
        }
      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formedit.get(nombreControl);
    return control.hasError(validacion);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  
}
