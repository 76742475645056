import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { IAddTransaction, iGetTransactionList, IRTransaction, iRTransactionList, IUpdateTransaction } from '../app/Interfaces/itransaction';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class TransactionService {
  private storage: any;

  baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  UpdateTransaction(Data: IUpdateTransaction) {
    try {
      let url = this.baseUrl + 'api/transaction/v1/update';
      return this.dataService.post<IgeneralResponse03>(url, Data).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }

  }

  Get(id:number) {
    try {
      let url = this.baseUrl + 'api/transaction/v1/';
      return this.dataService.get<IRTransaction>(url+id).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  GetTransactions(GTL:iGetTransactionList) {
    try {
      let url = this.baseUrl + 'api/transaction/v1/list/';
      return this.dataService.post<iRTransactionList>(url, GTL).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  AddTransaction(Data: IAddTransaction) {
    try {
      let url = this.baseUrl + 'api/transaction/v1/';
      return this.dataService.post<IgeneralResponse03>(url,Data).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }

  }
}
