import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DataService } from '../../../../services/data.service';
import { LocationService } from '../../../../services/location.service';
import { RouterService } from '../../../../services/router.service';
import { SecurityService } from '../../../../services/security.service';
import { ErrorStateMatcher1 } from '../../../error-state-matcher1';
import { Icc } from '../../../Interfaces/icc';
import { IlocationslistResponse, locationslist } from '../../../Interfaces/ilocation';
import { IuserPermissionsResponse } from '../../../Interfaces/iuser';

@Component({
  selector: 'app-locationslist',
  templateUrl: './locationslist.component.html',
  styleUrls: ['./locationslist.component.css']
})

export class LocationsListComponent implements OnInit {

  formReport: FormGroup;
  subRef$: Subscription;
  r_gridregs: 0;
  public companyId: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  DataGrid: IlocationslistResponse[] = [];
  dataList = null;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuPop: IuserPermissionsResponse[] = [];
  menuPopList = new MatTableDataSource;
  menuGList =new MatTableDataSource;
  filtermenu = { type: 'G' };
  _currentId: number;
  ccId: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private securityService: SecurityService,
    private routeparent: RouterService,
    private locationservice: LocationService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private securityParams: ActivatedRoute,
  ) {
    this.formReport = formBuilder.group({
      cc_id: [null, Validators.required]
    });
    this.Columns = ['code','name','statusname', 'cccode', 'b_menu'];
  }
  selectedvalue: number; selectedview: string;
  cc: Icc[];


  ngOnInit() {
    this.ccId = parseInt(this.securityParams.snapshot.params.ccid);
    this.mensaje = "";
    //permisos
    let url = environment.urlAPI + 'api/user/permission/';
    this.Permissions = [];
    this.MenuG = [];
    this.menuPop = [];
    this.dataService.get<IuserPermissionsResponse[]>(url + "LOCATIONS").subscribe(results => {
      if (results.body.length == 0)
        this.mensaje = "No tiene permisos";
      for (const entry of results.body) {
        this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
    },
      (e) => {
        this.mensaje  =  e.error;
        console.log(e);
      });
    this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
    this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
    this.menuPopList = new MatTableDataSource<IuserPermissionsResponse>(this.menuPop);


    //cc
    url = environment.urlAPI + 'api/cc/ccuser/';
    this.cc = []; 
    this.dataService.get<Icc[]>(url + "0").subscribe(results => {
      for (const entry of results.body) {
        this.addCc({ cc_id: entry.cc_id, cc_name: entry.cc_name });
      }
    }, (e) => {
      console.log(e);
    });
    this.filter();
  }


  filter() {
    //location
    const localist:  locationslist = {
      cc_id:this.ccId , // this.formReport.value.cc_id,
      type:"L"
    };
    this.mensaje = "";
    this.DataGrid = [];
    this.r_gridregs = 0;
    //const url = environment.urlAPI + 'api/location/list/';
    this.locationservice.List(localist).subscribe(results => {
      if (!results.body.isValid)
        this.openSB(results.body.error.description);
      for (const entry of results.body.resultData) {
        this.addData({ id: entry.id, code: entry.code, name: entry.name, status: entry.status, statusname: entry.statusname, cccode:"" });
      }
      this.dataList = new MatTableDataSource<IlocationslistResponse>(this.DataGrid);
      this.r_gridregs = this.dataList.data.length;
    },
      (e) => {
        this.openSB(e.error);
        console.log(e);
      });
  }


  addData(data) {
      this.DataGrid.push(data);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    switch (permission.type)
    {
      case "G":
        this.MenuG.push(permission);
        break;
      case "R":
        this.menuPop.push(permission);
        break;
    }
  }

  addCc(V_cc) {
    try {
      this.cc.push(V_cc);
    }
    catch (e) {
      console.log(e);
    }
  }

  b_CurrentId(p_Id: number) {
    this._currentId = p_Id;
//    this.appcustomerlistservice.putValues(this.formFilter.value.name, this.formFilter.value.idNumber, this.formFilter.value.status, this.paginator.pageIndex);
  }


  //execute menu options
  b_ExecutePop(PFunctionCode: string, PPrmCode: string, PLink: string) {
    //this.routeparent.putRouter("customerlist");
    var _Location = this.DataGrid.find(x => x.id == this._currentId);
    this.locationservice.putValues(this._currentId, _Location.name, _Location.countingnumber, _Location.countingtype);
    switch (PPrmCode) {
      case "P":
        //this.b_Production();
        break;
    }
  }

  //b_add(id, name) {
  //  this.routeparent.putRouter("locationslist");
  //  this.locationservice.putValues(id, name, 0, "")
  //  let _route = "locationadd/" + this.ccId;
  //  this.router.navigate([_route]);

  //}



  b_gen(link, id) {
    this.routeparent.putRoute("locationslist" + "/" + this.ccId);
    let _route =link+"/" + this.ccId;
    this.router.navigate([_route]);
  //  this.router.navigate([link]);
  }

  b_back() {
    this.router.navigate(['data']);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
