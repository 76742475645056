/// <reference path="../../../services/data.service.ts" />
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CompanyService } from '../../../services/company.service';
import { DataService } from '../../../services/data.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { IcompanyusersResponse } from '../../Interfaces/icompany';
import { IuserPermissionsResponse, Ipassword } from '../../Interfaces/iuser';
import { RouterService } from '../../../services/router.service';
import { UserService } from '../../../services/user.service';
import { IResponse } from '../../Interfaces/iresponse';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PermissionsService } from '../../../services/permissions.service';
//import { Location } from '@angular/common';

@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.css']
})

export class UserListComponent implements OnInit {
  subRef$: Subscription;
  r_usersregs: 0;
  public companyId: string;
  companyname: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  Users: IcompanyusersResponse[] = [];
  usersList = null;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList = new MatTableDataSource;
  menuPop: IuserPermissionsResponse[] = [];
  menuPopList = new MatTableDataSource;
  filtermenu = { type: 'G' };
  _currentId: number;
  IsMyCompany: boolean = false;
  //listarusuarios: boolean= false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private securityService: SecurityService,
    private companyservice: CompanyService,
    private routeparent: RouterService,
    private userservice: UserService,
    private _snackBar: MatSnackBar,
    private permissionsService: PermissionsService,
    //private location: Location
  ) {
    this.Columns = ['name','username', 'email1','sysstatusname','b_menu'];
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    this.mensaje = "";
    this.companyname = this.companyservice.companyName;
    //permisos
    let url = environment.urlAPI + 'api/user/permission/';
    this.Permissions = [];
    this.MenuG = [];
    this.menuPop = [];
    this.companyservice.IsItMyCompany(this.companyservice.companyID).subscribe(results => {
      if (results.body.isValid) {
        this.IsMyCompany = results.body.resultData;
        this.permissionsService.getPermission((this.IsMyCompany ? "USER" : "USERCMP")).subscribe(results => {
          if (results.body.length == 0)
            this.mensaje = "No tiene permisos";
          else {
            for (const entry of results.body) {
              this.addPermission(entry);
            }
            this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
            this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
            this.menuPopList = new MatTableDataSource<IuserPermissionsResponse>(this.menuPop);
          }
        },
          (e) => {
            this.mensaje = e.error;
            console.log(e);
          });
      }
      else {
        alert(results.body.error.description);
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
    //usuarios
    this.Users= [];
    this.r_usersregs = 0;
    if (this.companyservice.userList)
      url = environment.urlAPI + 'api/company/users/' + this.companyservice.companyID;
    else
      url = environment.urlAPI + 'api/company/myuser/';
    this.dataService.get<IcompanyusersResponse[]>(url).subscribe(results => {
      if (results.body.length == 0)
        this.mensaje = "No hay datos";
      for (const entry of results.body) {
        this.addUser({ id: entry.id,idparent:entry.idparent, name: entry.name, idtypename: entry.idtypename, email1: entry.email1,sysstatus:entry.sysstatus, sysstatusname:entry.sysstatusname, myuser:entry.myuser,username:entry.username});
      }
      this.usersList = new MatTableDataSource<IcompanyusersResponse>(this.Users);
      this.r_usersregs = this.usersList.data.length;
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }

  addUser(user) {
      this.Users.push(user);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    switch (permission.type) {
      case "G":
          switch (permission.code) {
            case "A": {
              if (this.companyservice.userList)
                this.MenuG.push(permission);
              break;
            }
            default: {
              this.MenuG.push(permission);
              break;
            }
          }
        break;
      case "R":
        {
          switch (permission.code) {
            case "S":
            case "O":
              var v_mod = this.menuPop.find(x => (x.code == "S" || x.code == "O"));
              if (v_mod == null) {
                permission.description = "MODIFICAR CONTRASEÑA";
                this.menuPop.push(permission);
              }
              break;
            default:
              this.menuPop.push(permission);
              break;
          }
          break;
        }
    }
  }    
  
  b_execute(prmCode: string, p_Link: string, p_Id: number) {
    this.routeparent.putRouter("userlist")
    var _User = this.Users.find(x => x.id == p_Id);
    switch (prmCode) {
      case "M":
        this.userservice.putValues(p_Id,_User.name)
        this.router.navigate(['/useredit']);
        break;
      case "O":
      case "S":
        try {
          if (_User.myuser) {
            this.userservice.putValues(p_Id,_User.name);
            this.router.navigate(["/pswedit"]);
          }
          else {
            var v_mod = this.Permissions.find(x => x.code == "O");
            if (v_mod != null) {
              var restaurar = confirm("Restaurar la contraseña para "+_User.name+"?");
              if (restaurar) {
                const ip: Ipassword = {
                  id: p_Id,
                  password: _User.username
                };
                let url = environment.urlAPI + 'api/user/password';
                this.dataService.put<IResponse>(url, ip).subscribe(results => {
                  if (results.ok) {
                    this.openSB(results.body.response);
                  }
                  else {
                    alert(results.body.response);
                  }
                },
                  (e) => {
                    alert(e.error);
                  });
              }
            }
            else
              alert("No tiene permitido la restauración de contrasenas")
          }
        }
        catch (e) {
          alert(e.message)
        }
        break;
      case "P":
        this.userservice.putValues(p_Id,_User.name)
        this.router.navigate(['/prmedit']);
        break;
      default:
        this.userservice.putValues(p_Id, _User.name)
        this.router.navigate([p_Link]);
        break;
    }
  }

  b_mod(id,name) {
  }

  b_prm(id, name) {
  }

  b_psw(id, mysuer, name,username) {
  }

  b_CurrentId(p_Id: number) {
    this._currentId = p_Id;
  }

  b_gen(link, id) {
        this.router.navigate([link]);
  }

  b_back() {
    this.router.navigate([this.routeparent.routerParent01]);
  }


  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
