import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { IRMenu01, IRMenu02 } from '../app/Interfaces/imenu01';
import { environment } from '../environments/environment';
import { DataService } from '../services/data.service';

@Injectable({ providedIn: 'root' })
export class MenuService {
  baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  getFunctionName(functionCode: string){
  try {
    let url = this.baseUrl + "api/menu/functionname/";
    return this.dataService.get<IgeneralResponse03>(url +functionCode).pipe(res => { return res });
  }
  catch (e) {
    console.log(e);
  }
}

  getOptionsLevel1(pUserId: number) {
    try {
      let url = this.baseUrl + "api/menu/n1/";
      return this.dataService.get<IRMenu01>(url + pUserId ).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  getOptionsLevel2(pUserId:number, pFunction: string) {
    try {
      let url = this.baseUrl + "api/menu/n2/";
      return this.dataService.get<IRMenu02>(url+pUserId+"/"+pFunction).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

}
