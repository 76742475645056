import { Component } from '@angular/core';
import { MatSnackBar, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterService } from '../../services/router.service';
import { DataTableService } from '../../services/dataTable.service';
import { PermissionsService } from '../../services/permissions.service';
import { ICode } from '../Interfaces/idatatable';
import { IuserPermissionsResponse } from '../Interfaces/iuser';

@Component({
  selector: 'transactionselect-data',
  templateUrl: './transactionselect.component.html'
})

export class TransactionSelectComponent {
  constructor(
    private router: Router,
    private securityParams: ActivatedRoute, 
    private dataTableService:DataTableService,
    private _snackBar: MatSnackBar,
    private routerService: RouterService,
    private permissionService: PermissionsService
  ) {
  }
  menuCC: any[];
  transactions: ICode[];
  ccId: number;
  centerName: string;
  message: string = "";
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList = new MatTableDataSource;
  path: string = "";
  Permissions: IuserPermissionsResponse[] = [];

  async ngOnInit() {
    this.ccId = parseInt(this.securityParams.snapshot.params.ccid);
    this.permissionService.getPermission("TRNLIST").subscribe(_Result => {
      if (_Result.body.length == 0)
        this.message = "No tiene permisos";
      for (const entry of _Result.body) {
        this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
    },
      (e) => {
        this.message = e.error;
        console.log(e);
      });

    this.load();
  }

  ngOnDestroy(): void {
  }

  private async load() {
    this.transactions= [];
    this.dataTableService.getDataListV3("TRANS").subscribe(result => {
      if (result.body.isValid) {
        for (const entry of result.body.resultData) {
          this.addTransaction({
            id: entry.id, textValue: entry.textValue.substring(0,22), code: entry.code,
            function_class: entry.function_class, backColor:entry.backColor});
        }
      }
      else {
        this.message = result.body.error.description;
      }
    },
      (e) => {
        console.log(e);
      });
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    switch (permission.type) {
      case "G":
        this.MenuG.push(permission);
        break;
      case "R":
        //this.menuPop.push(permission);
        break;
    }
  }

  b_click(PCode) {
    this.routerService.putRoute("transactionselect/"+this.ccId)
    let _Path = "transactionslist/" + this.ccId + "/" + PCode + "/"
    this.router.navigate([_Path], { skipLocationChange: true });
  }

  addTransaction(data) {
    this.transactions.push(data);
  }


  b_back() {
    let _backRoute= this.routerService.getBackRoute();
    this.router.navigate( [_backRoute]);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }
}






