/// <reference path="../../../services/data.service.ts" />
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatPaginator } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PASPrdService } from '../../../services/PASPrd.service';
import { PermissionsService } from '../../../services/permissions.service';
import { ProductionService } from '../../../services/production.service';
import { RouterService } from '../../../services/router.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { IgrouppaslistResponse } from '../../Interfaces/igrouppas';
import { IPASOptions } from '../../Interfaces/iproduction';
import { IuserPermissionsResponse } from '../../Interfaces/iuser';
import { PASAddComponent } from '../add/pasadd.component';
import { PASEditComponent } from '../edit/pasedit.component';

@Component({
  selector: 'app-pasoptionlist',
  templateUrl: './pasoptionlist.component.html'
})

export class PasOptionListComponent implements OnInit, AfterViewInit {

  formFilter: FormGroup;
  subRef$: Subscription;
  r_gridregs: number = 0;
  public companyId: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  message: string="";
  Columns: string[] = [];
  DataGrid:IPASOptions[] = [];
  dataList = new MatTableDataSource;
  Permissions: IuserPermissionsResponse[] = [];
  menuPop: IuserPermissionsResponse[] = [];
  menuPopList = new MatTableDataSource;
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList =new MatTableDataSource;
  filtermenu = { type: 'G' };
  _CurrentId: number;
  nGroupId: number;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataList.paginator = this.paginator;
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private securityService: SecurityService,
    private routeparent: RouterService,
    private productionService: ProductionService,
    private permissionService: PermissionsService,
    private pasPrdService: PASPrdService,
    private dialog: MatDialog,
    private securityParams: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) {
    //this.formFilter = formBuilder.group({
    //  option: [''],
    //  validator: ['x', Validators.required]
    //});
    this.Columns = ['code','option','b_menu'];
  }
  selectedvalue: number; selectedview: string;
  group: IgrouppaslistResponse[];


  ngOnInit() {
    this.message = "";
    this.nGroupId= parseInt(this.securityParams.snapshot.params.groupid);

    //permisos
    //let url = environment.urlAPI + 'api/user/permission/';
    this.Permissions = [];
    this.menuPop = [];
    this.MenuG = [];
    this.permissionService.getPermission("PASOPTIONS").subscribe(results => {
      if (results.body.length == 0)
        this.message = "No tiene permisos";
      for (const entry of results.body) {
        this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
      this.menuPopList = new MatTableDataSource<IuserPermissionsResponse>(this.menuPop);
    },
      (e) => {
        this.message  =  e.error;
        console.log(e);
      });
    this.Filter();
  }


  Filter() {
    //pasoptions
    this.message = "Consultando...";
    this.DataGrid = [];
    this.r_gridregs = 0;
    this.pasPrdService.GetGroupOptions(this.nGroupId).subscribe(result => {
      if (!result.body.isValid) {
        this.message = result.body.error.description;
      }
      else {
        this.message = "";
        for (const entry of result.body.resultData) {
          this.addData({
            idPASOptions: entry.idPASOptions, code: entry.code, option: entry.option
          });
        }
        this.dataList = new MatTableDataSource<IPASOptions>(this.DataGrid);
        this.dataList.paginator = this.paginator;
        this.r_gridregs = this.dataList.data.length;
      }
    },
      (e) => {
        this.openSB(e.error);
        console.log(e);
      });
  }


  addData(data) {
      this.DataGrid.push(data);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    switch (permission.type) {
      case "G":
        this.MenuG.push(permission);
        break;
      case "R":
        this.menuPop.push(permission);
        break;
    }
  }

  //b_det(id, name) {
  //  this.routeparent.putRouter("paslist");
  //  this.passervice.putValues(id, name)
  //  this.router.navigate(['/pasdetail']);
  //}

  b_back() {
    this.router.navigate(['pasoptionsgroup']);
  }


  b_gen(link, id, pCode) {
    switch (pCode) {
      case "A":
        this.b_Add();
        break;
      default:
        this.router.navigate([link]);
        break;
    }
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  b_Add() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "my-class";
    const dialogRef = this.dialog.open(PASAddComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          console.log("grabado");
        }
        else {
          console.log('Undefined data.');
        }
      }, (e) => console.log(e)
    );
  }

  b_Edit() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "my-class";
    const dialogRef = this.dialog.open(PASEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          console.log("grabado");
        }
        else {
          console.log('Undefined data.');
        }
      }, (e) => console.log(e)
    );
  }

  b_CurrentId(p_Id: number) {
    this._CurrentId = p_Id;
  }

  ////execute menu options
  //b_execute(PFunctionCode: string, PPrmCode: string, PLink: string) {
  //  this.routeparent.putRouter("customerlist");
  //  var _PASId = this.DataGrid.find(x => x.id == this._CurrentId);
  //  this.passervice.putValues(this._CurrentId,_PASId.name);
  //  //this.customerservice.putValues(this._currentId, _Customer.name)
  //  switch (PPrmCode) {
  //    case "M":
  //      this.b_Edit();
  //      break;
  //   case "P":
  //      this.b_Production();
  //      break;
  //    default:
  //      this.router.navigate([PLink]);
  //      break;
  //  }
  //}

}
