import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { IAddLocationProduction, ILocationList, locationadd, locationslist, RLocationAddV1 } from '../app/Interfaces/ilocation';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class LocationService {
  private storage: any;

  locationID: number = 0;
  locationName: string = "";
  countingnumber: number=0;
  countingtype: string = "";
  private baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  public putValues(locationid,locationname,countingnumber,countingtype) {
    this.locationID =locationid;
    this.locationName = locationname;
    this.countingnumber = countingnumber;
    this.countingtype = countingtype;
  }

  public Add(Location:locationadd) {
    try {
      let url = this.baseUrl + 'api/location/';
      return this.dataService.post<RLocationAddV1>(url, Location)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  public DeleteProduction(PLocation: number) {
    try {
      let url = this.baseUrl + 'api/location/production/';
      return this.dataService.delete<IgeneralResponse03>(url + PLocation)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  public EditProduction(PLocation:IAddLocationProduction) {
    try {
      let url = this.baseUrl + 'api/location/production/';
      return this.dataService.post<IgeneralResponse03>(url, PLocation)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  public AddProduction( PLocation:IAddLocationProduction) {
    try {
      let url = this.baseUrl + 'api/location/production/';
      return this.dataService.put<IgeneralResponse03>(url, PLocation)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  public List(p_LL: locationslist) {
    try {
      let url = this.baseUrl + 'api/location/list/';
      return this.dataService.post<ILocationList>(url, p_LL)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("error");
    }
  }
}
