import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CountService } from '../../../../services/count.service';
import { DataService } from '../../../../services/data.service';
import { LocationService } from '../../../../services/location.service';
import { PasService } from '../../../../services/pas.service';
import { PermissionsService } from '../../../../services/permissions.service';
import { RouterService } from '../../../../services/router.service';
import { SecurityService } from '../../../../services/security.service';
import { ErrorStateMatcher1 } from '../../../error-state-matcher1';
import { locationcount, locreport, pasdata, pasdataResponse } from '../../../Interfaces/ipas';
import { IuserPermissionsResponse } from '../../../Interfaces/iuser';

@Component({
  selector: 'app-locreport',
  templateUrl: './locreport.component.html',
  styleUrls: ['./locreport.component.css']
})

export class LocreportComponent implements OnInit, AfterViewInit {
  formReport: FormGroup;
  subRef$: Subscription;
  r_gridregs:number= 0;
  public companyId: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  mensaje_info: string = "";
  DataGrid: locationcount[] = [];
  dataList = new MatTableDataSource;
  Columns: string[] = [];
  Permissions: IuserPermissionsResponse[] = [];
  pasdataResponse: pasdataResponse;
  pasdata: pasdata;
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList =new MatTableDataSource;
  filtermenu = { type: 'G' };
  name: string = "";
  code: string = "";
  pas_id: number = 0;
  locationname: string = "";

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataList.paginator = this.paginator;
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private locationService: LocationService,
    private countService: CountService,
    private permissionsService: PermissionsService,
    private securityService: SecurityService,
    private routeparent: RouterService,
    private pasService: PasService,
    private _snackBar: MatSnackBar
  ) {
    this.formReport = formBuilder.group({
      code: [null]
    });
    this.Columns = ['code','name','presentation','duedate','quantity','countingnumber','username','hardwarename'];
  }
    changes: Subject<void>;
    itemsPerPageLabel: string;
    nextPageLabel: string;
    previousPageLabel: string;
    firstPageLabel: string;
    lastPageLabel: string;
    getRangeLabel: (page: number, pageSize: number, length: number) => string;

  selectedvalue: number; selectedview: string;

  ngOnInit() {
    //permisos
    this.locationname = this.locationService.locationName;
    //let url = environment.urlAPI + 'api/user/permission/';
    this.Permissions = [];
    this.MenuG = [];
    this.permissionsService.getPermission("COUNT-SC").subscribe(results => {
      if (results.body.length == 0) {
        this.mensaje = "No tiene permisos";
        this.mensaje_info = "";
      }
      for (const entry of results.body) {
        //this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource < IuserPermissionsResponse >(this.MenuG);
    },
      (e) => {
        this.mensaje = e.error;
        this.mensaje_info = "";
        console.log(e);
      });
  }

   

  filter() {
    const lc: locreport = {
      countingId:this.countService.countingID,
      pascode: this.formReport.value.code,
      location_id: this.locationService.locationID
    };
    this.mensaje = "";
    this.DataGrid = [];
    this.r_gridregs = 0;
    this.dataList = null;
    //let url = environment.urlAPI + 'api/count/location/';
    this.countService.CountLocation(lc).subscribe(results => {
      if (!results.body.isValid)
        this.openSB(results.body.error.description);
      for (const entry of results.body.resultData) {
        this.addData({
          id: entry.id, code: entry.code, name: entry.name, presentation: entry.presentation,
          duedate: entry.duedate.substring(0, 10), quantity: entry.quantity, countingnumber: entry.countingnumber,
          user_id: entry.user_id, username: entry.username, hardware_id: entry.hardware_id, hardwarename: entry.hardwarename
        });
      }
      this.dataList = new MatTableDataSource<locationcount>(this.DataGrid);
      this.dataList.paginator = this.paginator;
      this.r_gridregs = this.DataGrid.length;

    },
      (e) => {
        this.openSB(e.error);
        console.log(e);
      });
  }

  addData(data) {
    this.DataGrid.push(data);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    if (permission.type == "G") {
          this.MenuG.push(permission);
    }
    else {
    }
  }



  b_gen(link, id) {
        this.router.navigate([link]);
  }

  b_back() {
    this.router.navigate(['locationselect']);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formReport.get(nombreControl);
    return control.hasError(validacion);
  }

}
