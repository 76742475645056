import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig, MatSnackBar, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationService } from '../../../services/location.service';
import { PermissionsService } from '../../../services/permissions.service';
import { RouterService } from '../../../services/router.service';
import { SrvLocationService } from '../../../services/SrvLocation.service';
import { IServiceLocationInCC, IServiceZone } from '../../Interfaces/ilocation';
import { IuserPermissionsResponse } from '../../Interfaces/iuser';
import { CustomServLocationComponent } from './customServLocation.component';

declare interface Location {
  locationName: string;
  userName: string;
  difference: string;
  differenceMinutes: number;
  percentage: number;
  class: string;
}

@Component({
  selector: 'editservlocation-data',
  templateUrl: './editservlocation.component.html'
})

export class EditServLocationComponent {
  constructor(
    private router: Router,
    //private locationService: LocationService,
    private srvLocationService: SrvLocationService,
    private securityParams: ActivatedRoute,
    private permissionsService: PermissionsService,
    private routerService: RouterService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {
  }
  menuLocations: any[];
  _result: boolean = false;
  locations: IServiceLocationInCC[];
  zones: IServiceZone[];
  ccId: number;
  centerName: string;
  message: string = "";
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList = new MatTableDataSource;

  async ngOnInit() {
    this.ccId = parseInt(this.securityParams.snapshot.params.ccid);
    this.Permissions = [];
    this.MenuG = [];
    this.permissionsService.getPermission("SERVLOCATION").subscribe(result => {
      if (result.body.length == 0)
        this.message = "No tiene permisos";
      for (const entry of result.body) {
        this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
    },
      (e) => {
        this.message = e.error;
        console.log(e);
      });
    //
    this.locations = [];
    this.menuLocations = [];
    this.loadServiceLocations();
  }

  ngOnDestroy(): void {
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    switch (permission.type) {
      case "G":
        this.MenuG.push(permission);
        break;
    }
  }

  private async loadServiceLocations() {
    this.zones = [];
    this.locations = [];
    this.menuLocations = [];
    this.srvLocationService.GetZones(this.ccId).subscribe(result => {
      if (result.body.isValid) {
        for (const entry of result.body.resultData) {
          this.addZone({ zone: entry.zone, zoneName: "Zona " + entry.zone.toString() });
        }
        this.srvLocationService.GetServiceLocationsInCC(this.ccId).subscribe(result => {
          if (result.body.isValid) {
            for (const entry of result.body.resultData) {
              this.addLocations({
                id: entry.id, name: entry.name.substring(0, 20).trim(), userName: entry.userName.substring(0, 20).trim(), code: entry.code,
                userIdAttends: entry.userIdAttends, class: entry.function_class, color01: entry.color01, color02: entry.color02,zone: entry.zone
              });
            }
            this.menuLocations = this.locations; //.filter(menuLocation => menuLocation);
          }
          else {
            this.message = result.body.error.description;
          }
        },
          (e) => {
            alert(e);
          });
        return this.locations;

        //this
      }
      else {
        this.message = result.body.error.description;
      }    
    })
  }

  addZone(zone) {   
    this.zones.push( zone);
  }

  addLocations(route) {
    this.locations.push(route);
  }

  filterZone(zone: number) {
    try {
      var x = this.menuLocations.filter(x => x.zone == zone);
        return x;
    } catch {
      return null;
    }
  }

  Custom(PLocationId) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "my-class";
    dialogConfig.data =  PLocationId ;
    const dialogRef = this.dialog.open(CustomServLocationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          var _Loc = this.menuLocations.find(x => x.id == PLocationId);
          _Loc.color01 = data.color01;
          _Loc.color02 = data.color02;
        }
        else {
        }
      }, (e) => console.log(e)
    );
  }


  b_back() {
    this.router.navigate([this.routerService.routerParent]);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }
}






