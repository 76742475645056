import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CcService } from '../../services/cc.service';
import { DataTableService } from '../../services/dataTable.service';
import { PermissionsService } from '../../services/permissions.service';
import { RouterService } from '../../services/router.service';
import { SecurityService } from '../../services/security.service';
import { TransactionService } from '../../services/transaction.service';
import { ErrorStateMatcher1 } from '../error-state-matcher1';
import { Icc } from '../Interfaces/icc';
import { ConfigTable } from '../Interfaces/idatatable';
import { iGetTransactionList, iRTransactionList, transactionStatus, trnTechProvider } from '../Interfaces/itransaction';
import { IuserPermissionsResponse } from '../Interfaces/iuser';
import { TransactionAddComponent } from './transactionadd.component';
import { TransactionUpdateComponent } from './transactionupdate.component';

@Component({
  selector: 'app-transactionslist',
  templateUrl: './transactionslist.component.html'
})

export class TransactionsListComponent implements OnInit, AfterViewInit {

  formFilter: FormGroup;
  subRef$: Subscription;
  r_gridregs:number= 0;
  public companyId: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  message: string="";
  Columns: string[] = [];
  DataGrid:iRTransactionList[] = [];
  dataList = new MatTableDataSource;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  menuPop: IuserPermissionsResponse[] = [];
  menuPopList = new MatTableDataSource;
  permissionsList = null;
  menuGList =new MatTableDataSource;
  filtermenu = { type: 'G' };
  r_b_cxc:boolean= false;
  r_b_det: boolean = false;
  _currentId: number;
  ccId: number;
  trnCode: string;
  trnName: string="";

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataList.paginator = this.paginator;
  }


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private securityService: SecurityService,
    private ccService: CcService,
    private routeparent: RouterService,
    private permissionservice: PermissionsService,
    private dialog: MatDialog,
private transactionService:TransactionService,
    private dataTableService: DataTableService,
    private securityParams: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) {
    this.formFilter = formBuilder.group({
      /*ccId: [''],*/
/*      transactionCode: ['', Validators.required],*/
      status: [1, Validators.required]
    });
    this.Columns = ['prefixcode','prefix','number', 'authorization','techprovidername','numberlimitlower','numberlimitupper','datelimitlower','datelimitupper','b_menu'];
  }
  selectedvalue: number; selectedview: string;
  status:transactionStatus[];
  cc: Icc[];
  transactions: ConfigTable[] = [];

  ngOnInit() {
    this.ccId = parseInt(this.securityParams.snapshot.params.ccid);
    this.trnCode =this.securityParams.snapshot.params.trncode.toString();
    this.Columns.pop;
    this.message = "";
    //permisos
    this.Permissions = [];
    this.MenuG = [];
    this.menuPop = [];
    this.dataTableService.getCodeV1("TRANS", this.trnCode).subscribe(_result => {
      if (_result.body.isValid) {
        this.trnName = _result.body.resultData.textvalue;
      }
      else {
        this.message =_result.body.error.description;
      }
    })
    this.permissionservice.getPermission("TRNLIST").subscribe(_Result => {
      if (_Result.body.length == 0)
        this.message = "No tiene permisos";
      for (const entry of _Result.body) {
        this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
    },
      (e) => {
        this.message= e.error;
        console.log(e);
      });
      //cc
    //this.cc = [];
    //this.ccService.getCCListV2().subscribe(results => {
    //  if (results.body.isValid) {
    //    for (const entry of results.body.resultData) {
    //      this.addCc({ cc_id: entry.cc_id, cc_name: entry.cc_name });
    //    }
    //  }
    //  (e) => {
    //    console.log(e);
    //  }
    //});
    //transactions
    this.dataTableService.getDataListV2("TRANS").subscribe(_res => {
      if (_res.body.isValid) {
        for (const entrytable of _res.body.resultData) {
          this.transactions.push({ table: entrytable.table, name: entrytable.textvalue, code: entrytable.code });
        }
      }
    })
    //TransactionStatus
    this.status = [];
    this.status.push({ name: "Activa", code: 1 });
    this.status.push({ name: "Inactiva", code: 2 });
    this.menuPopList = new MatTableDataSource<IuserPermissionsResponse>(this.menuPop);
    this.Filter();
  }

  Filter() {
    const filt: iGetTransactionList = {
      ccId:this.ccId/* this.formFilter.value.ccId*/, transactionCode:this.trnCode, /* this.formFilter.value.transactionCode,*/
      status: this.formFilter.value.status
    };
    //this.message = "";
    this.DataGrid = [];
    this.r_gridregs = 0;

    this.transactionService.GetTransactions(filt).subscribe(results => {
      if (!results.body.isValid)
        this.message = results.body.error.description;
      else {
        for (const entry of results.body.resultData) {
          this.addData({
            idtransaction:entry.idtransaction, code: entry.code, prefixcode: entry.prefixcode, prefix: entry.prefix,
            number: entry.number, numberlimitlower: entry.numberlimitlower, numberlimitupper: entry.numberlimitupper,
            datelimitlower: entry.datelimitlower.toString().substring(0, 10),
            datelimitupper: entry.datelimitupper.toString().substring(0,10), 
            authorization: entry.authorization, techprovider: entry.techprovider, copies: entry.copies,
           imc:entry.imc });
        }
        this.dataList = new MatTableDataSource<iRTransactionList>(this.DataGrid);
        this.dataList.paginator = this.paginator;
        this.r_gridregs = this.dataList.data.length;
      }
    },
      (e) => {
        this.message = e.error;
        console.log(e);
      });
  }

  addCc(V_cc) {
    try {
      this.cc.push(V_cc);
    }
    catch (e) {
      console.log(e);
    }
  }


  addData(data) {
    let _providerName=trnTechProvider[ data.techprovider];
    data.techprovidername = _providerName;
      this.DataGrid.push(data);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    switch (permission.type) {
      case "G":
        this.MenuG.push(permission);
        break;
      case "R":
        this.menuPop.push(permission);
        break;
    }
  }

  b_del(id, name) {
    //this.providerService.putValues(id, name)
    this.router.navigate(['/customerdelete']);
  }

  b_gen(PPrmCode,link, id)
  {
    switch (PPrmCode) {
      case "A":
        if (this.trnCode == "02" || this.trnCode=="03" || this.trnCode=="06" ) {
          this.b_Add();
        }
        else {
          this.openSB("En construcción");
        }
        break;
      default:
        this.router.navigate([link]);
        break;
    }
  }

  b_Det() {
    const dialogConfig = new MatDialogConfig();
    //dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "dialogViewer";
    //const dialogRef = this.dialog.open(ProviderDetailComponent, dialogConfig);
    //dialogRef.afterClosed().subscribe(
    //  data => {
    //  }, (e) => console.log(e)
    //);
  }

  b_Add() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "my-class";
    dialogConfig.data={ ccid:this.ccId, trncode:this.trnCode };
    const dialogRef = this.dialog.open(TransactionAddComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          console.log("grabado");
          this.Filter();

        }
        else {
          console.log('Undefined data.');
        }
      }, (e) => console.log(e)
    );
  }

  b_Mod(id:number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "my-class";
    dialogConfig.data = { id: id};

    //dialogConfig.width= "100%!important";
    const dialogRef = this.dialog.open(TransactionUpdateComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          //console.log("grabado");
          //let _CurrentPage = this.paginator.pageIndex;
          //if (this.r_gridregs > 0) {
          //  this.paginator.pageIndex = _CurrentPage;
          this.Filter();
          //}
        }
        else {
          console.log('Undefined data.');
        }
      }, (e) => console.log(e)
    );

  }

  b_CurrentId(p_Id: number) {
    this._currentId = p_Id;
  }

  b_back() {
    let _backRoute = this.routeparent.getBackRoute();
    this.router.navigate([_backRoute]);
  }

  //execute menu options
  b_popexecute(FunctionCode: string, PPrmCode: string, PLink: string) {
    switch (PPrmCode) { 
    case "M":
    if (this.trnCode == "02" || this.trnCode == "03" || this.trnCode == "06") {
      this.b_Mod(this._currentId);
    }
    else {
      this.openSB("En construcción");
    }
    break;
  }


    //this.routeparent.putRouter("providerlist");
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
