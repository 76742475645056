import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { getLocationAssigned, GRLocationAssigned, IAddLocationProduction, IGRLocationProductionResponse, IGRLocationProductionV2, IGRProductionLocation, IGRServiceLocation, IGRServiceLocationInCC, IGRServiceLocationInProduction, IlocationslistResponse, IPutServiceLocationV1, IServiceZones, locationslist, putUserLocation } from '../app/Interfaces/ilocation';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class SrvLocationService {
  private storage: any;

  locationID: number = 0;
  locationName: string = "";
  countingnumber: number=0;
  countingtype: string = "";
  private baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  public putValues(locationid,locationname,countingnumber,countingtype) {
    this.locationID =locationid;
    this.locationName = locationname;
    this.countingnumber = countingnumber;
    this.countingtype = countingtype;
  }

  public GetZones(CCId : number) {
    try {
      let url = this.baseUrl + 'api/srvlocation/v1/servicezones/';
      return this.dataService.get<IServiceZones>(url+CCId).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  public PutUserLocation(p_PUL: putUserLocation[]) {
    try {
      let url = this.baseUrl + 'api/srvlocation/v1/userlocation/';
      return this.dataService.post<IgeneralResponse03>(url, p_PUL).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  //put service location
  public PutServiceLocation(PSL: IPutServiceLocationV1) {
    try {
      let url = this.baseUrl + 'api/srvlocation/v1/';
      return this.dataService.post<IgeneralResponse03>(url, PSL)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  //get service location
  public GetServiceLocation(PLocationId: number) {
    try {
      let url = this.baseUrl + 'api/srvlocation/v1/';
      return this.dataService.get<IGRServiceLocation>(url + PLocationId)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  //get service locations by CC
  public GetServiceLocationsInCC(PCCId: number) {
    try {
      let url = this.baseUrl + 'api/srvlocation/v1/servicecc/';
      return this.dataService.get<IGRServiceLocationInCC>(url + PCCId)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  public Assigned(p_LL: getLocationAssigned) {
    try {
      let url = this.baseUrl + 'api/srvlocation/v1/assigned/';
      return this.dataService.post<GRLocationAssigned>(url, p_LL)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("error");
    }
  }

  //get service locations in production
  public GetServiceLocationsInProduction(PLocationId: number) {
    try {
      let url = this.baseUrl + 'api/srvlocation/v1/service/';
      return this.dataService.get<IGRServiceLocationInProduction>(url + PLocationId)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }




}
