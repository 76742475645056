import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
//import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TransactionService } from '../../services/transaction.service';
//import { DataTableService } from '../../../services/dataTable.service';
//import { ProviderService } from '../../../services/provider.service';
//import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../error-state-matcher1';
//import { IdatatableResponse } from '../../Interfaces/idatatable';
//import { IProviderRegistry } from '../../Interfaces/iprovider';
import { IUpdateTransaction, listTrnStage, listTrnStatus, listTrnTechProvider, trnStage, trnStatus, trnTechProvider } from '../Interfaces/itransaction';

@Component({
  selector: 'app-transactionupdate',
  templateUrl: './transactionupdate.component.html',
})

export class TransactionUpdateComponent implements OnInit {
  formedit: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  message: string="";
  id: number;

  constructor(
    private formBuilder: FormBuilder,
    private securityParams: ActivatedRoute, 
    private router: Router,
    private trnService: TransactionService,
    //private datatableservice: DataTableService,
    private dialogRef: MatDialogRef<TransactionUpdateComponent>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data:any
  )
  {
    this.formedit = formBuilder.group({
      //prefix: ['', [Validators.required, Validators.maxLength(10)]],
      authorization: ['', [Validators.required, Validators.maxLength(30)]],
      numberlimitlower: [1, [Validators.required]],
      numberlimitupper: [2, [Validators.required]],
      number: [1, [Validators.required]],
      datelimitlower: [Date.now, [Validators.required]],
      datelimitupper: [Date.now, [Validators.required]],
      text01: [''],
      key01: ['', Validators.required],
      imc: [false],
      techprovider: [trnTechProvider.NA, [Validators.required]],
      stage: [trnStage.NA, [Validators.required]],
      status: [true, [Validators.required]]
    });
  }
  selectedvalue: number; selectedview: string;
  listStatus: listTrnStatus[] = [{ code: 1, name: 'Activo' }, { code: 2, name: 'Inactivo' }];
  listStage: listTrnStage[] = [{ code: 1, name: 'NA' }, { code: 2, name: 'Pruebas' }, { code: 3, name: 'Habilitación' }, { code: 4, name: 'Producción' }]; /*, { code:3, name: 'No aplica' }*/
  listTechProvider: listTrnTechProvider[] = [{ code: 1, name: 'NA' }, { code: 2, name: 'GSIGMA-SPH' }, { code: 3, name: 'GSIGMA-F1' }];


  ngOnInit() {
    this.id = this.data.id; /* parseInt(this.securityParams.snapshot.params['ccid']);*/
    this.message = "PRECAUCION: Si esta conectado a una aplicación externa ,actualice cuando dicha aplicación no se encuentre en uso, ya que podria causar errores impredecibles por la afectación del consecutivo.";
    this.load();
  }

  Cancelar() {
    this.dialogRef.close();
  }

  load() {
    this.trnService.Get(this.id).subscribe(result => {
      if (result.body.isValid) {
        this.formedit.get('authorization').setValue(result.body.resultData.authorization);
        this.formedit.get('numberlimitlower').setValue(result.body.resultData.numberlimitlower);
        this.formedit.get('numberlimitupper').setValue(result.body.resultData.numberlimitupper);
        this.formedit.get('number').setValue(result.body.resultData.number);
        this.formedit.get('datelimitlower').setValue(result.body.resultData.datelimitlower.toString().substring(0,10));
        this.formedit.get('datelimitupper').setValue(result.body.resultData.datelimitupper.toString().substring(0,10));
        this.formedit.get('text01').setValue(result.body.resultData.text01);
        this.formedit.get('key01').setValue(result.body.resultData.key01);
        this.formedit.get('imc').setValue(result.body.resultData.imc);
        //this.formedit.get('techprovider').setValue(trnTechProvider[result.body.resultData.techprovider]);
        this.formedit.get('techprovider').setValue(result.body.resultData.techprovider);
        this.formedit.get('stage').setValue( result.body.resultData.stage);
        this.formedit.get('status').setValue((result.body.resultData.status == 1 ? true : false));
      }
      else {
        alert(result.body.error.description);
      }
    }, (e) => {
      //this.mensaje = e.error;
      alert(e.error);
    });
  }

  Save()
  {
    try {
      let _Status = (this.formedit.value.status? trnStatus.ACTIVE : trnStatus.INACTIVE);
      let _Stage = this.formedit.value.stage; // (this.formedit.value.stage == 1 ? trnStage.NA : this.formedit.value.stage == 2 ? trnStage.TESTING : this.formedit.value.stage == 3 ? trnStage.ENABLING : trnStage.PRODUCTION);
      let _TechProvider = this.formedit.value.techprovider; // (this.formedit.value.techprovider == 1 ? trnTechProvider.NA : this.formedit.value.techprovider == 2 ? trnTechProvider.SPH : trnTechProvider.F1);
      const IData:IUpdateTransaction =
      {
        id:this.id,  authorization:this.formedit.value.authorization, stage:_Stage,
        dateLimitLower: this.formedit.value.datelimitlower, dateLimitUpper: this.formedit.value.datelimitupper,
        copies: 1, status:_Status, imc: this.formedit.value.imc, number: this.formedit.value.number,
        numberLimitLower: this.formedit.value.numberlimitlower, numberLimitUpper: this.formedit.value.numberlimitupper,
        key01: this.formedit.value.key01, key02: "", key03: "", text01: this.formedit.value.text01, text02: "", text03: "",
        techProvider:_TechProvider
      };
      this.trnService.UpdateTransaction(IData).subscribe(results => {
        if (results.body.isValid)
        {
          this.openSB(results.body.resultData.description);
          this.dialogRef.close(true);
        }
        else
        {
          alert(results.body.error.description);
        }
      },
        (e) => {
          //this.mensaje = e.error;
          alert(e.error);
        }
      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formedit.get(nombreControl);
    return control.hasError(validacion);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  
}
