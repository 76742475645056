import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAddCompanyFromCustomer, IcompanyAdd, ICompanyData, IcompanyResponse, IGetCompaniesList, IGRMyCompanies, IGRMyCompaniesData, IIsItMyCompany, IPutFiscalData } from '../app/Interfaces/icompany';
import { GRFiscalData } from '../app/Interfaces/ientitytaxes';
import { IgeneralResponse01 } from '../app/Interfaces/igeneralresponse';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class CompanyService {

  private baseUrl = environment.urlAPI;

  companyID: number = 0;
  companyName: string = "";
  filterName: string = "";
  idNumber: string=""
  userList: boolean = false;
  currentPage: number = 0;
  constructor(private http: HttpClient, private dataService: DataService) { }

  public putValues(companyid, companyname, userlist) {
    this.companyID = companyid;
    this.userList = userlist;
    this.companyName = companyname;
  }

  public putFilterValues(PName,PIdNumber,PCurrentPage) {
    this.filterName = PName;
    this.idNumber = PIdNumber;
    this.currentPage = PCurrentPage;
  }

  IsItMyCompany(CompanyId: number) {
    try {
      let url = this.baseUrl + 'api/company/v1/isitmycompany/';
      return this.dataService.get<IIsItMyCompany>(url + CompanyId).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  UpdateFiscalData(PFiscalData: IPutFiscalData) {
    try {
      let url = this.baseUrl + 'api/company/v2/fiscaldata/';
      return this.dataService.post<IgeneralResponse03>(url, PFiscalData).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  GetFiscalData( PCompanyId:number) {
    try {
      let url = this.baseUrl + 'api/company/fiscaldata/';
      return this.dataService.get<GRFiscalData>(url+PCompanyId).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  PutFiscalData(PFiscalData:IPutFiscalData) {
    try {
      let url = this.baseUrl + 'api/company/v1/fiscaldata/';
      return this.dataService.post<IgeneralResponse03>(url, PFiscalData).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  GetMyCompaniesData() {
    try {
      let url = this.baseUrl + 'api/company/v1/mycompaniesdata/';
      return this.dataService.get<IGRMyCompaniesData>(url).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  GetMyCompany(PCompanyId:number) {
    try {
      let url = this.baseUrl + 'api/company/id/';
      return this.dataService.get<ICompanyData>(url + PCompanyId).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  GetMyCompanies(PGet: IGetCompaniesList) {
    try {
      let url = this.baseUrl + 'api/company/v1/mycompanies/';
      return this.dataService.post<IGRMyCompanies>(url,PGet).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  GetCompaniesList() {
    try
    {
      let url = this.baseUrl + 'api/company/list/';
      return this.dataService.get<IGRMyCompanies>(url).pipe(res => { return res });
    }
    catch (e)
    {
      console.log(e);
    }
  }

  CheckbyID(PId:number) {
    try {
      let url = this.baseUrl + 'api/company/v2/id/';
      return this.dataService.get<IgeneralResponse03>(url + PId).pipe(res => { return res });
    }
    catch (e) { console.log(e); }
  }

  AddFromCustomer(PCmp:IAddCompanyFromCustomer) {
    try {
      let url = this.baseUrl + 'api/company/v1/fromcustomer';
      return this.dataService.post<IgeneralResponse03>(url, PCmp)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  Registry(PCmp : IcompanyAdd) {
    try {
      let url = this.baseUrl + 'api/company/v1/';
      return this.dataService.post<IgeneralResponse01>(url, PCmp)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }


  //public putId(id) {
  //  this.companyID = id;
  //}
    
  
}
