import { GR04 } from "./igeneralresponse";

export interface IRPASOptionsGroupSelect extends GR04 {
  resultData: IPASOptionsGroupSelect[];
}
export interface IPASOptionsGroupSelect {
  id: number;
  code: string;
  name: string;
  type: POGType;
  selected: boolean;
  toggled: boolean;
  typeName: string;
}

export interface IRPASOptionsGroup extends GR04 {
  resultData: IPASOptionsGroup[]; // IPASInProduction[];
}
export interface IPASOptionsGroup {
  idpasoptionsgroup: number;
  code: string;
  name: string;
  companyId: number;
  status: POGStatus;
  type: POGType;
  typeName: string;
}

export enum POGStatus
{
  ACTIVE=1,INACTIVE=2
}

export enum POGType {
  UNIQUESELECTION = 1, MULTIPLESELECTION=2
}

export interface IGRPASOptions extends GR04
{
  resultData: IPASOptions[];
}
export interface IPASOptions {
  idPASOptions: number;
  code: string;
  option: string;
  status: string;
}

export interface IOrderId {
  orderId: number;
}

export interface IGRPASInProduction extends GR04 {
  resultData: IPASInProduction[];
}

export interface IPASInProduction {
  id: number;
  requestOrder: number;
  locationName: string;
  status: string;
  pasNameUm: string;
  quantity: number;
  textOptions: string;
  difference:string;
  differenceMinutes: number;
  onTime: number;
  function_class: string;
  color01: string;
  color02: string;
  color03: string;
  parentId: number;
  compType: number;   

}

