import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from '../../services/menu.service';
import { RouterService } from '../../services/router.service';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  color: string;
  cols: number;
  rows: number;
  class: string;
  backColor: string;
}

export const ROUTES: RouteInfo[] = [];

@Component({
  selector: 'menu02-data',
  templateUrl: './menu02.component.html'
})


export class Menu02Component {

  constructor(
    //private dataService: DataService,
    //private routeparent: RouterService,
    private router: Router,
    private menuService: MenuService,
    private securityParams: ActivatedRoute,
        private routerService: RouterService
  ) { }

  menuItems: any[];
  functions: RouteInfo[];
  mainFunction: string;
  title: string;


  async ngOnInit() {
    this.mainFunction = (this.securityParams.snapshot.params.functioncode).toString().toUpperCase();
  }

  LoadContent()
  {
    //this.routeparent.putRouter("data");
    this.functions =[];
    this.menuItems = [];
    this.menuService.getFunctionName(this.mainFunction).subscribe(results => {
      if (results.body.isValid) {
        this.title = results.body.resultData.description
      }
      else {
        this.title = "Error";
      }
    });
    this.menuService.getOptionsLevel2(0,this.mainFunction).subscribe(results => {
      for (const entry of results.body.resultData) {
        this.addRoutes({
          path: '/' + entry.link.trim().toLowerCase(), title: entry.description.trim(),
          icon: entry.icon, color: '#A51955', cols: 1, rows: 1, class: entry.function_class, backColor: entry.backColor 
        });
      }
      this.menuItems = this.functions.filter(menuItem => menuItem);
    },
      (e) => {
        console.log(e);
      });
    this.menuItems = this.functions.filter(menuItem => menuItem);
  }

  addRoutes(route) {
    this.functions.push(route);
  }

  b_back() {
    //this.router.navigate(['home']);
    this.mainFunction = this.routerService.getBackRoute();
    this.LoadContent();
  }

  clickMenu(Route, Id) {
    this.routerService.putRoute(this.mainFunction)
    //this.routerService.putRouter("prodlocation");
    Route += "/" + Id
    this.router.navigate([Route]);
  }
}






