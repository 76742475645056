/// <reference path="../../../services/data.service.ts" />
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatPaginator } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppService } from '../../../services/app.service';
import { CcService } from '../../../services/cc.service';
import { DataService } from '../../../services/data.service';
import { PasService } from '../../../services/pas.service';
import { PasGroupService } from '../../../services/pasGroup.service';
import { PermissionsService } from '../../../services/permissions.service';
import { RouterService } from '../../../services/router.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { Icc } from '../../Interfaces/icc';
import { IgrouppaslistResponse } from '../../Interfaces/igrouppas';
import { ICompanyList, paslist } from '../../Interfaces/ipas';
import { IuserPermissionsResponse } from '../../Interfaces/iuser';
import { PASAddComponent } from '../add/pasadd.component';
import { PASEditComponent } from '../edit/pasedit.component';
import { AddPASProductionComponent } from '../production/addpasproduction.component';

@Component({
  selector: 'app-paslist',
  templateUrl: './paslist.component.html',
  styleUrls: ['./paslist.component.css']
})

export class PasListComponent implements OnInit, AfterViewInit {

  formFilter: FormGroup;
  subRef$: Subscription;
  r_gridregs: number = 0;
  public companyId: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  message: string="";
  Columns: string[] = [];
  DataGrid: ICompanyList[] = [];
  dataList = new MatTableDataSource;
  Permissions: IuserPermissionsResponse[] = [];
  menuPop: IuserPermissionsResponse[] = [];
  menuPopList = new MatTableDataSource;
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList =new MatTableDataSource;
  filtermenu = { type: 'G' };
  _CurrentId: number;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataList.paginator = this.paginator;
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private securityService: SecurityService,
    private ccService: CcService,
    //private companyservice: CompanyService,
    private routeparent: RouterService,
    private passervice: PasService,
    private pasgroupservice: PasGroupService,
    private permissionService: PermissionsService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private appService: AppService
  ) {
    this.formFilter = formBuilder.group({
/*      cc_id: [this.appService.ccId, Validators.required],*/
      id: [this.appService.groupId, Validators.required],
      pasName: [this.appService.pasName, Validators.required],
      validator: ['x', Validators.required]
    });
    this.Columns = ['code','name','presentation', 'brand','groupname','typename', 'b_menu'];
  }
  selectedvalue: number; selectedview: string;
  cc: Icc[];
  group: IgrouppaslistResponse[];


  ngOnInit() {
    const {id,pasName} = this.formFilter.controls;
    //subscribe id
    this.subRef$ =id.valueChanges.subscribe(
      (idSelect) => {
        if (idSelect ==0) {
          this.formFilter.controls['pasName'].setValidators([Validators.required]);
        }
        else {
          this.formFilter.controls['pasName'].setValidators(null);
        }
        this.formFilter.controls["pasName"].updateValueAndValidity();
      }
    );
    this.message= "";
    //permisos
    this.Permissions = [];
    this.menuPop = [];
    this.MenuG = [];
    this.permissionService.getPermission("PAS").subscribe(results => {
      if (results.body.length == 0)
        this.message = "No tiene permisos";
      for (const entry of results.body) {
        this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource < IuserPermissionsResponse >(this.MenuG);
    },
      (e) => {
        this.message  =  e.error;
        console.log(e);
      });
    this.menuPopList = new MatTableDataSource<IuserPermissionsResponse>(this.menuPop);
    //cc
    this.cc = []; 
    this.ccService.getCCList().subscribe(results => {
      for (const entry of results.body) {
        this.addCc({ cc_id: entry.cc_id, cc_name: entry.cc_name });
      }
    }, (e) => {
      console.log(e);
    });
    //group
    this.group = [];
    this.pasgroupservice.GetGroups(0).subscribe(results => {
      this.addpasgroup({ id: 0, code: '', name: 'Todos ', longname: 'Todos' });
      for (const entry of results.body.resultData) {
        this.addpasgroup({ id: entry.id, code: entry.code, name:entry.name, longname:entry.longname });
      }
    }, (e) => {
      console.log(e);
    });
    if ((this.formFilter.value.id > 0 || this.formFilter.value.pasName.length > 0)) //this.formFilter.value.cc_id > 0 &&
    {
      this.Filter();
    }

  }

  Filter() {
    //pas
    const paslis: paslist = {
      cc_id: 0, // this.formFilter.value.cc_id
      group_id: this.formFilter.value.id,
      pasId: 0,
      pasName:this.formFilter.value.pasName
    };
    this.message = "Procesando...";
    this.DataGrid = [];
    this.r_gridregs = 0;
    this.passervice.getCompanyList(paslis).subscribe(result => {
      if (!result.body.isValid) {
        this.message = result.body.error.description;
      }
      else {
        this.message = "";
        for (const entry of result.body.resultData) {
          this.addData({
            id: entry.id, code: entry.code, name: entry.name, presentation: entry.presentation, groupname: entry.groupName,
            typename: entry.typeName, brand: entry.brand
          });
        }
        this.dataList = new MatTableDataSource<ICompanyList>(this.DataGrid);
        if (this.appService.currentPage > 0) {
          this.paginator.pageIndex = this.appService.currentPage;
        }
        this.dataList.paginator = this.paginator;
        this.r_gridregs = this.dataList.data.length;
      }
    },
      (e) => {
        this.openSB(e.error);
        console.log(e);
      });
  }


  addData(data) {
      this.DataGrid.push(data);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    switch (permission.type) {
      case "G":
        this.MenuG.push(permission);
        break;
      case "R":
        this.menuPop.push(permission);
        break;
      //switch (permission.code) {
      //  case "C": {
      //    this.Columns.push("b_det");
      //    break;
      //  }
    }
  }

  addCc(V_cc) {
    try {
      this.cc.push(V_cc);
    }
    catch (e) {
      console.log(e);
    }
  }

 addpasgroup(v_group) {
  try {
    this.group.push(v_group);
  }
  catch (e) {
    console.log(e);
  }
}

  b_det(id, name) {
    this.routeparent.putRouter("paslist");
    this.passervice.putValues(id, name)
    this.router.navigate(['/pasdetail']);
  }

  b_back() {
    this.appService.PASListPutValues(0,0,"",0);
    this.router.navigate(['pasmenu']);
  }


  b_gen(link, id, pCode) {
    switch (pCode) {
      case "A":
        this.b_Add();
        break;
      default:
        this.router.navigate([link]);
        break;
    }
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  b_Add() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "my-class";
    const dialogRef = this.dialog.open(PASAddComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          console.log("grabado");
        }
        else {
          console.log('Undefined data.');
        }
      }, (e) => console.log(e)
    );
  }

  b_Edit() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "my-class";
    const dialogRef = this.dialog.open(PASEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          console.log("grabado");
        }
        else {
          console.log('Undefined data.');
        }
      }, (e) => console.log(e)
    );
  }

  b_Production() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "my-class";
    const dialogRef = this.dialog.open(AddPASProductionComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          console.log("grabado");
        }
        else {
          console.log('Undefined data.');
        }
      }, (e) => console.log(e)
    );
  }

  b_CurrentId(p_Id: number) {
    this._CurrentId = p_Id;
  }

  //execute menu options
  b_execute(PFunctionCode: string, PPrmCode: string, PLink: string) {
    this.routeparent.putRouter("customerlist");
    var _PASId = this.DataGrid.find(x => x.id == this._CurrentId);
    this.passervice.putValues(this._CurrentId, _PASId.name);
    this.appService.PASListPutValues(0, this.formFilter.value.id, this.formFilter.value.pasName, this.paginator.pageIndex);//this.formFilter.value.cc_id
    //this.customerservice.putValues(this._currentId, _Customer.name)
    switch (PPrmCode) {
      case "M":
        this.b_Edit();
        break;
     case "P":
        this.b_Production();
        break;
      case "O":
        PLink=PLink+"/"+this._CurrentId
        this.router.navigate([PLink]);
        break;
      default:
        this.router.navigate([PLink]);
        break;
    }
  }

}
