//import { MatCurrencyFormatModule } from 'mat-currency-format';
import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, Pipe } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CustomerService } from '../../../services/customer.service';
import { DataService } from '../../../services/data.service';
import { RouterService } from '../../../services/router.service';
import { SecurityService } from '../../../services/security.service';
import { Icxc02 } from '../../Interfaces/icxc';
import { IgeneralResponse02, IgeneralResponse03 } from '../../Interfaces/igeneralresponse02';

@Component({
  selector: 'app-customercxc',
  templateUrl: './customercxc.component.html'
  
})

@Pipe({ name: 'safeHtml' })
export class CustomercxcComponent implements OnInit  {

  url: string;
  urlSafe: SafeResourceUrl;
  html: any;
  subRef$: Subscription;
  mensaje: string = "";


  constructor(
    public sanitizer: DomSanitizer,
    private dataService: DataService,
    private securityService: SecurityService,
    private router: Router,
    private customerservice: CustomerService,
    private routeparent: RouterService,
    private currencyPipe: CurrencyPipe,
    private _snackBar: MatSnackBar
  ) {}


  ngOnInit() {
    const cxc: Icxc02 = {
      cc_id: 0,
      customer_id: this.customerservice.customerID
    };
    let url = environment.urlAPI + 'api/informes/html/cxc02/';
    this.dataService.post<IgeneralResponse03>(url, cxc).subscribe(results => {
      if (!results.body.isValid) {
        console.log(results.body.error.description);
        this.mensaje = results.body.error.description;
        this.openSB(this.mensaje);
      }
      else {
        this.html = this.assembleHTMLItem(results.body.resultData.description) ;
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }

  assembleHTMLItem(vp) {
    var strHTML =  vp;
    return this.sanitizer.bypassSecurityTrustHtml(strHTML);
  }

  Back()
  {
    this.router.navigate([this.routeparent.routerParent]);
  }

  b_back() {
    this.router.navigate([this.routeparent.routerParent]);
  }

        openSB(msg) {
          this._snackBar.open(msg, '', {
            duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
          });
        }


}
