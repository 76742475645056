import { HttpClient } from "@angular/common/http";
import { environment } from "../environments/environment";
import { Injectable } from '@angular/core';
import { IResponse } from "../app/Interfaces/iresponse";
import { DataService } from "./data.service";
import { IRCompanyUrlFiles } from "../app/Interfaces/ifiles";
import { IgeneralResponse03 } from "../app/Interfaces/igeneralresponse02";

@Injectable()

export class FileService {
  private baseUrl = environment.urlAPI;
  constructor(private http: HttpClient, private dataService: DataService) { }

  //public upload(formData: FormData) {
  //  let url = environment.urlAPI + 'api/count/finished/v2/';
  //  return this.http.post(url , formData, {
  //    reportProgress: true,
  //    observe: 'events',
  //  });
  //}

  download(vp_CCId) {
    let url = this.baseUrl+ 'api/count/finished/v2/'+vp_CCId.toString();
    return this.http.get(url, {reportProgress:true,observe:'events', responseType:'blob'});
  }

  //upArionPAS(PFormData: FormData) {
  //  try {
  //    let url = this.baseUrl + 'api/file/v1/arionpas';
  //    return this.dataService.post<IResponse>(url ,PFormData)
  //      .pipe(res => { return res });
  //  }
  //  catch (e) {
  //    console.log("Error");
  //  }
  //}

  async upArionPAS2(PFormData: FormData) {
    try {
      let url = this.baseUrl + 'api/file/v1/arionpas';
      return await this.dataService.post<IResponse>(url, PFormData)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  ListUrlFiles() {
    try {
      let url = this.baseUrl + 'api/urlfiles/v1/company';
      return this.dataService.get<IRCompanyUrlFiles>(url)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  UpKeruxVData() {
    try {
      let url = this.baseUrl + 'api/inventory/v1/keruxv/';
      return this.dataService.post<IgeneralResponse03>(url,null)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }
  

  //function promisifiedGetPaidSetup(): Promise<boolean> {
  //return new Promise(resolve =>
  //  (window as any).getpaidSetup({
      // resolve with a boolean value, cleaner design since you don't set a variable outside
  //    callback: response => resolve(response != null)
  //  })
  //);
//}

}

