import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { DataService } from '../../services/data.service';
import { MenuService } from '../../services/menu.service';
import { RouterService } from '../../services/router.service';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  color: string;
  cols: number;
  rows: number;
  class: string;
}

export const ROUTES: RouteInfo[] = [];

@Component({
  selector: 'pasmenu-data',
  templateUrl: './pasmenu.component.html'
})


export class PASMenuComponent {

  constructor(
    private dataService: DataService,
    private routeparent: RouterService,
private menuService:MenuService,
    private router: Router
  ) { }

  menuItems: any[];
  functions: RouteInfo[];


  async ngOnInit() {
    this.routeparent.putRouter("pasmenu");
    const userId = localStorage.getItem('userId');
    this.functions =[];
    this.menuItems = [];
    this.menuService.getOptionsLevel2(Number(userId), 'PASMENU').subscribe(results => {
      for (const entry of results.body.resultData) {
        this.addRoutes({
          path: '/' + entry.link.trim().toLowerCase(), title: entry.description.trim(), icon: entry.icon,
          color: '#A51955', cols: 1, rows: 1, class: entry.function_class, backColor:entry.backColor
        });
      }
      this.menuItems = this.functions.filter(menuItem => menuItem);
    },
      (e) => {
        console.log(e);
      });
    this.menuItems = this.functions.filter(menuItem => menuItem);
  }

  addRoutes(route) {
    this.functions.push(route);
  }

  b_back() {
    this.router.navigate(['home']);

  }

}






