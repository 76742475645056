import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CompanyService } from '../../../services/company.service';
import { DataService } from '../../../services/data.service';
import { PermissionsService } from '../../../services/permissions.service';
import { RouterService } from '../../../services/router.service';
import { SecurityService } from '../../../services/security.service';
import { UserService } from '../../../services/user.service';
import { putPermissions, putUserPermissions } from '../../Interfaces/ifunction';
import { IgrPermissions, IgrPermissionsStructure } from '../../Interfaces/igeneralresponse02';
import { IPermissions } from '../../Interfaces/iuser';

interface CfgNode {
  description: string;
  id: number;
  children?: CfgNode[];
}

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  description: string;
  level: number;
  id: number;
}

@Injectable()
export class FileDatabase {
}

@Component({
  selector: 'app-prmedit',
  templateUrl: './prmedit.component.html'
})

export class PrmeditComponent {
  formPrm: FormGroup;
  private _transformer = (node: CfgNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      description: node.description,
      id: node.id,
      level: level,
    };
  };


  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(

    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  constructor(
    private formBuilder: FormBuilder,
    private companyservice: CompanyService,
    private dataService: DataService,
    private userservice: UserService,
    //private securityService: SecurityService,
    private routerparent: RouterService,
    private router: Router,
    private permissionsService: PermissionsService,
    private _snackBar: MatSnackBar) {
    this.formPrm = formBuilder.group({
      boolvalue: [true],
      numbervalue: [0],
      stringvalue: ['']
    });
    this.Columns = ['description', 'selected'];
    this.initialize();
  }
  mensaje: string;
  Configurations: CfgNode[] = [];
  //Permissions: IPermissions[] = [];
  permissionsList = null;
  companyname: string;
  r_type: string;
  r_text: string;
  r_id: number;
  r_instructions: string = "";
  username: string = "";
  functionselected: boolean = false;
  Columns: string[] = [];
  SaveInProgress: boolean= false;



  initialize() {
    this.mensaje = "";
    this.username = this.userservice.userName;
    //get structure
    let url = environment.urlAPI + 'api/user/v1/permissionsstructure/'+this.companyservice.companyID;
    this.dataService.get<IgrPermissionsStructure>(url).subscribe(results => {
      if (results.body.isValid) {
        this.dataSource.data = results.body.resultData;
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
    //get permissions
    url = environment.urlAPI + 'api/user/v2/allpermissions/' + this.companyservice.companyID+'/'+this.userservice.userID;
    this.dataService.get<IgrPermissions>(url).subscribe(results => {
      if (results.body.isValid) {
        this.permissionsList = new MatTableDataSource<IPermissions>(results.body.resultData);
        this.permissionsList.filterPredicate = (data:
          { functionId: number }, filterValue: number) =>
          data.functionId == filterValue;
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }

  b_Cancel() {
    this.router.navigate([this.routerparent.routerParent])
  }

  async Save() {
    try {
      this.SaveInProgress = true;
      const pp: putPermissions[] = [];
      for (const entry of this.permissionsList.data) {
        if (entry.selected)
          pp.push({ id: entry.id, type: entry.type });
      }
      const pup: putUserPermissions = {
        userId: this.userservice.userID,
        permissions: pp
      };
      //const url = environment.urlAPI + 'api/user/v2/permissions';
      //this.dataService.put<IgeneralResponse03>(url, pup).subscribe(results => {
      //const _x = new this.permissionsService();

      //let controller = new AbortController;
      //this.permissionsService.PutBYUser2(pup) = new Promise((res, rej) => {
      //  setTimeout(() => { res("promesa") }, 2000);
      //});

      //const _Req;
      //this.permissionsService.PutBYUser2(pup);
      //
      ( await this.permissionsService.PutByUser(pup)).subscribe(results => {
        if (results.ok) {
          this.openSB(results.body.resultData.description);
          this.router.navigate([this.routerparent.routerParent]);
        }
        else {
          this.SaveInProgress = false;
          alert("Error");
        }
      },
        (e) => {
          alert(e.error);
          this.SaveInProgress =false;
        }
      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
      this.SaveInProgress = false;
    }
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }


  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  onClick(node) {
    this.r_instructions = "";
    try {
      this.r_id = node.id;
      this.r_type = node.type;
      this.r_text = node.description;
      this.functionselected = false;
      try {
        //if (node.children != null) {
        this.permissionsList.filter = node.id;
        this.functionselected = true;
        //}
      }
      catch (e) {
        console.log(e.message);
        alert(e.message);
      }
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  onChange(getId, xnode) {

    try {
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  selectPrm(item) {
    if (item["selected"] == true)
      item["selected"] = false;
    else
      item["selected"] = true;
  }

  xclick(Row) {
    //var x = this.permissionsList.data[id];
    this.r_instructions = Row.toolTip; // this.permissionsList.data[id].toolTip;
    //this.r_instructions = x;
    //console.log("x");

  }

  searchNode(id) {
    var node = this.dataSource.data.find(x => x.id == id)
    //for (int i = 0; this.dataSource._flattenedData.value.find(x => x.id == id).stringvalue;
  }

}
