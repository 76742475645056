import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IgeneralResponse } from '../app/Interfaces/igeneralresponse';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { GRMinPrice, GRPASCCV1, GRPASCompany, GRPASCompanyList, GRPASLevelCC, ICCPASUpdate, ICompanyPASModify, IGRAllPASTaxes, IGRDataProduction, IPutDataProduction, IPutPAS, pasdata, pasdataResponse, paslist, putPASTaxes, putPrices, RPASData } from '../app/Interfaces/ipas';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class PasService {
  private storage: any;

  pasID: number = 0;
  pasName: string = "";

  cost: number = 0;
  price: number = 0;
  newPrice: number = 0;
  taxes: number = 0;
  minPercentage: number = 0;

  baseUrl = environment.urlAPI;
  pasdata: pasdata;

  constructor(private http: HttpClient, private dataService: DataService) { }

  public putValues(pasid,pasname) {
    this.pasID = pasid;
    this.pasName = pasname;
  }

  public UpFileStatus(PFormData: FormData) {
    try {
      let url = this.baseUrl + 'api/pas/v1/changestatus/0';
      return this.dataService.post<IgeneralResponse03>(url, PFormData)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  public putPrices(pCost:number,pPrice: number, pNewPrice: number, pTaxes: number, pMinPercentage: number) {
    this.cost = pCost;
    this.price = pPrice; this.newPrice = pNewPrice; this.taxes = pTaxes, this.minPercentage = pMinPercentage;
  }

  ////Delete data production in CC
  //public DeleteDataProduction(PId:number) {
  //  let url = environment.urlAPI + 'api/pas/v1/dataproduction/';
  //  try {
  //    return this.dataService.delete<IgeneralResponse03>(url+PId).
  //      pipe(res => { return res });
  //  }
  //  catch (e) {
  //    console.log(e);
  //  }
  //}

//obsolete
  //Put data production in CC
  //public PutDataProduction(PDataProduction: IPutDataProduction) {
  //  let url = environment.urlAPI + 'api/pas/v1/dataproduction/';
  //  try {
  //    return this.dataService.put<IgeneralResponse03>(url, PDataProduction).
  //      pipe(res => { return res });
  //  }
  //  catch (e) {
  //    console.log(e);
  //  }
  //}

  ////add data production in CC
  //public AddDataProduction(PDataProduction: IPutDataProduction) {
  //  let url = environment.urlAPI + 'api/pas/v1/dataproduction/';
  //  try {
  //    return this.dataService.post<IgeneralResponse03>(url, PDataProduction).
  //      pipe(res => { return res });
  //  }
  //  catch (e) {
  //    console.log(e);
  //  }
  //}

  //list production data 
  public DataProduction(PCCId:number , PPASId : number) {
    let url = environment.urlAPI + 'api/pas/v1/dataproduction/';
    try {
      return this.dataService.get<IGRDataProduction>(url+PCCId+"/"+PPASId).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  //get minprice
  public getMinPrice(PPASId: number, PType: string) {
    let url = environment.urlAPI + 'api/pas/v1/minprice/';
    try {
      return this.dataService.get<GRMinPrice>(url + PPASId + "/" + PType).
        pipe(res => { return res });
    }
    catch (e) { console.log(e); }
  }

  //Delete taxes
  public deleteTaxes(PPASId: number, PTaxId: number)
  {
    let url = environment.urlAPI + 'api/pas/v1/taxes/';
    try {
      return this.dataService.delete<IgeneralResponse03>(url+PPASId+"/"+PTaxId).
        pipe(res => { return res });
    }
    catch (e) { console.log(e); }
  }

  //put taxes
  public putTaxes(PTaxes: putPASTaxes[]) {
    let url = environment.urlAPI + 'api/pas/v1/puttaxes';
    try {
      return this.dataService.post<IgeneralResponse03>(url, PTaxes).
        pipe(res => { return res });
    }
    catch (e) { console.log(e); }
  }

  //list data PAS basic in all CCs
  public listIdCC(pFilter: paslist) {
    let url = environment.urlAPI + 'api/pas/v1/list/cc/';
    try {
      return this.dataService.post<GRPASCCV1>(url, pFilter).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  public getCompanyList(pFilter: paslist) {
    let url = environment.urlAPI + 'api/pas/v2/list/';
    try {
      return this.dataService.post<GRPASCompanyList>(url, pFilter).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  public getAllPASTaxes(pPASId: number) {
    let url = environment.urlAPI + 'api/pas/v1/alltaxes/';
    try {
      return this.dataService.get<IGRAllPASTaxes>(url + pPASId.toString()).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  public getDataCompanyV1(pId: number) {
    let url = environment.urlAPI + 'api/pas/v1/company/id/';
    try {
      return this.dataService.get<GRPASCompany>(url + pId.toString()).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  public getDataCCV1(pCCId:number, pId: number) {
    let url = environment.urlAPI + 'api/pas/v1/cc/id/';
    try {
      return this.dataService.get<IgeneralResponse>(url+pCCId.toString()+"/"+pId.toString()).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  public savePrices(pPrice: putPrices) {
    let url = environment.urlAPI + 'api/pas/putprice/';
    try {
      return this.dataService.post<IgeneralResponse03>(url, pPrice).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  //add PAS 
  addPAS(pPAS: IPutPAS) {
    let url = environment.urlAPI + 'api/pas/v3/';
    try {
      return this.dataService.post<IgeneralResponse03>(url, pPAS).
        pipe(res => {
          return res
        });
    }
    catch (e) {
      console.log(e);
    }
  }

  //Modify PAS Company
  ModifyPAS(pPAS: ICompanyPASModify) {
    let url = environment.urlAPI + 'api/pas/v1/company/';
    try {
      return this.dataService.put<IgeneralResponse03>(url, pPAS).
        pipe(res => {
          return res
        });
    }
    catch (e) {
      console.log(e);
    }
  }

  //Modify PAS CC
  UpdatePASCC(pPAS: ICCPASUpdate) {
    let url = environment.urlAPI + 'api/pas/v1/cc/';
    try {
      return this.dataService.put<IgeneralResponse03>(url, pPAS).
        pipe(res => {
          return res
        });
    }
    catch (e) {
      console.log(e);
    }
  }

  //Base prices level cc
  getBasePrices(pascode: string) {
    let url = environment.urlAPI + 'api/pas/baseprices/';
    try
    {
      const pasdat: pasdata = {
        code: pascode,
        cc_id: 0
      };
      return this.dataService.post<GRPASLevelCC>(url, pasdat)
        .pipe(res => {
          return res
        });
    }
    catch (e) {
      console.log(e);
    }
  }

//level cc
  cccodeexist(pascode: string, ccid: number) {
    let url = environment.urlAPI + 'api/pas/data/';
    const pasdat: pasdata = {
      code: pascode,
      cc_id: ccid
    };
    return this.dataService.post<RPASData>(url, pasdat)
      .pipe(res => {
        return res
      });
  }

}
