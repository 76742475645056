import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GRBackgroundProcess } from '../app/Interfaces/ibackgroundprocess';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class BackgroundProcessService {

  private baseUrl = environment.urlAPI;
  constructor(private http: HttpClient, private dataService: DataService) { }

  GetStatus(PCode:string) {
    try {
      let url = this.baseUrl + 'api/backgroundprocess/v1/status/';
      return this.dataService.get<GRBackgroundProcess>(url+PCode).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  Cancel(PCode: string) {
    try {
      let url = this.baseUrl + 'api/backgroundprocess/v1/cancel/';
      return this.dataService.delete<IgeneralResponse03>(url+PCode, null).pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  
}
