import { Injectable } from '@angular/core';

@Injectable()
export class RouterService {

  routerParent: string ="";
  routerParent01: string = "";
  routerParent02: string = "";
  route: string[]=[];

  public putRouter(router) {
    this.routerParent = router;
  }
  public putRouter01(router) {
    this.routerParent01 = router;
  }
  public putRouter02(router) {
    this.routerParent02 = router;
  }

  public putRoute(PRoute:string) {
    this.route.push(PRoute);
  }

  getBackRoute() {
    try {
      let _route = this.route[this.route.length - 1];
      this.route.pop();
      return _route;
    }
    catch (e) {
      console.log("Error");
    }
  }
  
}
