import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DataService } from '../../../../services/data.service';
import { LocationService } from '../../../../services/location.service';
import { RouterService } from '../../../../services/router.service';
import { SecurityService } from '../../../../services/security.service';
import { ErrorStateMatcher1 } from '../../../error-state-matcher1';
import { IccResponse } from '../../../Interfaces/icc';
import { IgeneralResponse02 } from '../../../Interfaces/igeneralresponse02';
import { locationadd } from '../../../Interfaces/ilocation';

@Component({
  selector: 'app-locationadd',
  templateUrl: './locationadd.component.html'
})

export class LocationaddComponent implements OnInit {
  formadd: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  Cc: IccResponse[] = [];
  ccList = null;
  r_ccregs: 0;
  ccId: number;
  route: string;

  constructor(
    private formBuilder: FormBuilder, 
    private dataService: DataService,
    private securityService: SecurityService,
    private router: Router,
    private locationservice: LocationService,
    private _snackBar: MatSnackBar,
    private securityParams: ActivatedRoute,
    private routeparent: RouterService,

  ) {
    this.formadd = formBuilder.group({
      code: ['', Validators.required],
      name: ['', Validators.required]
    //  ,ccid: [0]
    });
  }
  selectedvalue: number; selectedview: string;



  ngOnInit() {
    this.ccId = parseInt(this.securityParams.snapshot.params.ccid);
    this.mensaje = "";
    //cc
    this.Cc = [];
    let url = environment.urlAPI + 'api/cc/mycompany';
    this.dataService.get<IccResponse[]>(url).subscribe(results => {
      if (results.body.length == 0)
        this.mensaje = "No hay datos";
      for (const entry of results.body) {
        this.addCc({ id: entry.id, idparent: entry.idparent, name: entry.name, code: entry.code, type: entry.type });
      }
      //this.ccList = new MatTableDataSource<IccResponse>(this.Cc);
      //this.r_ccregs = this.ccList.data.length;
    }, (e) => {
      console.log(e);
    });
  }

  addCc(cc) {
    this.Cc.push(cc);
  }


  Grabar()
  {
    try {
      const iadd:locationadd = {
        code:this.formadd.value.code,
        name: this.formadd.value.name,
        cc_id:this.ccId , // this.formadd.value.ccid,
        status: "A",
          type:"L"
      };
      const url = environment.urlAPI + 'api/location/';
      this.dataService.post<IgeneralResponse02>(url,iadd).subscribe(results => {
        if (results.ok) {
          this.openSB(results.body.resultData.description);
          this.router.navigate(['/locationslist']);
        }
        else { alert("Error"); }
      },
        (e) => {
          alert(e.error);
        }
      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  Cancelar()
  {
    this.route=this.routeparent.getBackRoute();
          this.router.navigate([this.route]);
  }



  hasError(nombreControl: string, validacion: string) {
    const control = this.formadd.get(nombreControl);
    return control.hasError(validacion);
  }

  openSB(msg) {
    this._snackBar.open(msg,'', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }


}
