import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BackgroundProcessService } from '../../services/backgroundprocess.service';
import { CompanyService } from '../../services/company.service';
import { FileService } from '../../services/file.service';
import { PermissionsService } from '../../services/permissions.service';
import { RouterService } from '../../services/router.service';
import { ErrorStateMatcher1 } from '../error-state-matcher1';
import { ICompanyUrlFile } from '../Interfaces/ifiles';
import { IuserPermissionsResponse } from '../Interfaces/iuser';


@Component({
  selector: 'app-processfiles',
  templateUrl: './processfiles.component.html'
})

export class ProcessFilesComponent implements OnInit {


  subRef$: Subscription;
  r_FilesRegs: 0;
  public companyId: string;
  companyname: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  Files: ICompanyUrlFile[] =[];
  filesList = null;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList =new MatTableDataSource;
  filtermenu = { type: 'G' };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private companyservice: CompanyService,
    private routeparent: RouterService,
    private _snackBar: MatSnackBar,
    private permissionService: PermissionsService,
    private fileService: FileService,
    private backgroundService: BackgroundProcessService
  ) {
    this.Columns = ['process', 'b_run', 'b_del'];
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    this.mensaje = "";
    this.companyname = this.companyservice.companyName;
    //permisos
    this.Permissions = [];
    this.MenuG = [];
    this.permissionService.getPermission("PROCESSFILES").subscribe(results => {
      if (results.body.length == 0)
        this.mensaje = "No tiene permisos";
      for (const entry of results.body) {
        this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource < IuserPermissionsResponse >(this.MenuG);
    },
      (e) => {
        this.mensaje  =  e.error;
        console.log(e);
      });
    //cc
    this.Files= [];
    this.r_FilesRegs = 0;
    this.fileService.ListUrlFiles().subscribe(results => {
      if (results.body.isValid) {
        //this.mensaje = resul.error.description;
        for (const entry of results.body.resultData) {
          this.addFiles(entry);
        }
        this.filesList = new MatTableDataSource<ICompanyUrlFile>(this.Files);
        this.r_FilesRegs = this.filesList.data.length;
      }
      else {
        this.mensaje = results.body.error.description;
      }
    },
      (e) => {
        this.mensaje = e.error;
        alert(e.error);
        console.log(e);
      });
  }

  addFiles(file) {
      this.Files.push(file);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    if (permission.type == "G") {
      switch (permission.code) {
        case "A": {
          if (this.companyservice.userList)
            this.MenuG.push(permission);
          break;
        }
        default: {
          this.MenuG.push(permission);
          break;
        }
      }
    }
    else {
      switch (permission.code) {
        case "M": {
          this.Columns.push("b_mod");
          break;
        }
        case "B": {
          this.Columns.push("b_del");
          break;
        }
      }
    }
  }

  b_exe(processCode) {
    switch (processCode) {
      case "KVFIL":
        this.fileService.UpKeruxVData().subscribe(result => {
          if (result.body.isValid) {
            this.mensaje = result.body.resultData.description;
          }
          else {
            this.mensaje = result.body.error.description;
          }
        },
          (e) => {
            this.mensaje = e.error;
            alert(e.error);
          });
        break;
    }
  }

  b_del(processCode) {
    switch (processCode) {
      case "KVFIL":
        this.backgroundService.Cancel(processCode).subscribe(result => {
          if (result.body.isValid) {
            this.mensaje = result.body.resultData.description;
          }
          else {
            this.mensaje = result.body.error.description;
          }
        },
          (e) => {
            this.mensaje = e.error;
            alert(e.error);
          });
        break;
    }
  }


  b_back() {
   // this.location.back();
    this.router.navigate([this.routeparent.routerParent01]);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
