//import { MatCurrencyFormatModule } from 'mat-currency-format';
import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, Pipe } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CourseService } from '../../../services/course.service';
import { DataService } from '../../../services/data.service';
import { RouterService } from '../../../services/router.service';
import { SecurityService } from '../../../services/security.service';
import { grCourseContent } from '../../Interfaces/igeneralresponse02';

declare interface RouteInfo {
  id: number;
  title: string;
  parentId: number;
}

@Component({
  selector: 'app-contentbasic',
  templateUrl: './contentbasic.component.html'
})

@Pipe({ name: 'safeHtml' })
export class ContentBasicComponent implements OnInit {

  url: string;
  urlSafe: SafeResourceUrl;
  html: any;
  subRef$: Subscription;
  mensaje: string = "";
  _coursetitle: string = this.courseService.name;
  _contenttitle: string;
  _pagination: string;
  _i: number;
  functions: RouteInfo[];
  menuItems: any[];
  _childrenCount: number = -1;

  constructor(
    public sanitizer: DomSanitizer,
    private courseService: CourseService,
    private dataService: DataService,
    //private securityService: SecurityService,
    private router: Router,
    //private routeparent: RouterService,
    //private currencyPipe: CurrencyPipe,
    private _snackBar: MatSnackBar
  ) { }


  ngOnInit() {
    this.url = environment.urlAPI + 'api/course/v1/';
    this.loadContent();
  }

  loadContent() {
    this.functions = [];
    this.menuItems = [];
    this.dataService.get<grCourseContent>(this.url + this.courseService.id + "/" + this.courseService.contentid).subscribe(results => {
      if (!results.body.isValid) {
        console.log(results.body.error.description);
        this.mensaje = results.body.error.description;
        this.openSB(this.mensaje);
      }
      else {
        this._contenttitle = (results.body.resultData.sectionName == "" ? "" : results.body.resultData.sectionName + " : ") + results.body.resultData.name;
        this._pagination = results.body.resultData.secuence + "/" + results.body.resultData.chapters + " | ";
        this.courseService.putValues(this.courseService.id, this.courseService.name, results.body.resultData.id, results.body.resultData.parentId)
        for (const entry of results.body.resultData.children) {
          this.addRoutes({ id: entry.id, title: entry.name.trim().toUpperCase() });
        }
        this.menuItems = this.functions.filter(menuItem => menuItem);
        this._childrenCount = this.menuItems.length;
        this.html = this.assembleHTMLItem(results.body.resultData.htmlcontent);
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }

  assembleHTMLItem(vp) {
    var strHTML = vp;
    return this.sanitizer.bypassSecurityTrustHtml(strHTML);
  }

  b_back() {
    if (this.courseService.parentId == 0) {
      this.router.navigate(["courseslist"]);
    }
    else {
      this.courseService.putValues(this.courseService.id, this.courseService.name, this.courseService.parentId, 0)
      this.loadContent();
    }
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  addRoutes(route) {
    this.functions.push(route);
  }

  b_next(id) {
    this.courseService.putValues(this.courseService.id, this.courseService.name, id, this.courseService.id)
    this.loadContent();
  }

  b_end() {
    this.router.navigate(["courseslist"]);
  }

}
