import { GR04 } from "./igeneralresponse";

export interface IUpdateTransaction {
  id: number;
  number: number;
  authorization: string;
  numberLimitLower: number;
  numberLimitUpper: number;
  dateLimitLower: Date;
  dateLimitUpper: Date;
  techProvider: trnTechProvider;
  copies: number;
  imc: boolean;
  stage: trnStage;
  status: trnStatus;
  key01: string;
  key02: string;
  key03: string;
  text01: string;
  text02: string;
  text03: string;
}

export interface IAddTransaction {
  ccId: number;
  code: string;
  number: number;
  prefix: string;
  numberLimitLower: number;
  numberLimitUpper: number;
  dateLimitLower: Date;
  dateLimitUpper: Date;
  authorization: string;
  techProvider: trnTechProvider;
  copies: number;
  imc: boolean;
  stage: trnStage;
  status: trnStatus;
  key01: string;
  key02: string;
  key03: string;
  text01: string;
  text02: string;
  text03: string;
}

export interface iGetTransactionList {
  ccId: number;
  transactionCode: string;
  status: number;
}
export interface transactionStatus {
  name: string;
  code: number;
}

export interface IRTransaction extends GR04
{
  resultData: ITransaction01;
}

export interface iRTransactionList extends GR04 {
  resultData: iTransactionList[];
}

export interface iTransactionList {
  idtransaction: number;
  code: string;
  prefixcode: string;
  prefix: string;
  number: number;
  numberlimitlower: number;
  numberlimitupper: number;
  datelimitlower: Date;
  datelimitupper: Date;
  authorization: string;
  techprovider: trnTechProvider;
  techprovidername: string;
  copies: number;
  imc: boolean;
}

export interface ITransaction01 extends  iTransactionList {
  stage: trnStage;
  status: trnStatus;
  text01: string;
  key01: string;
}

export interface SaveTransaction {
  prefix: string;
  authorization: string;
  numberLimitLower: number;
  numberLimitUpper: number;
  number: number;
  dateLimitLower: Date;
  dateLimitUpper: Date;
  text01: string;
  key01: string;
  imc: boolean;
  status: trnStatus;
  stage: trnStage;
  techProvider: trnTechProvider;
}


export enum trnStatus {
  ACTIVE = 1, INACTIVE = 2
}
export enum trnTechProvider {
  NA=1,SPH = 2, F1 = 3
}
export enum trnStage {
  NA=1,TESTING = 2, ENABLING = 3, PRODUCTION = 4
}

export interface listTrnStatus {
  name: string;
  code: trnStatus;
}

export interface listTrnTechProvider {
  name: string;
  code: trnTechProvider;
}

export interface listTrnStage {
  name: string;
  code: trnStage;
}



