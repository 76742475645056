import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CcService } from '../../../../services/cc.service';
import { CountService } from '../../../../services/count.service';
import { DataService } from '../../../../services/data.service';
import { LocationService } from '../../../../services/location.service';
import { PermissionsService } from '../../../../services/permissions.service';
import { RouterService } from '../../../../services/router.service';
import { SecurityService } from '../../../../services/security.service';
import { ErrorStateMatcher1 } from '../../../error-state-matcher1';
import { IlocationslistResponse, locationslist } from '../../../Interfaces/ilocation';
import { IuserPermissionsResponse } from '../../../Interfaces/iuser';

@Component({
  selector: 'app-locationselect',
  templateUrl: './locationselect.component.html',
  styleUrls: ['./locationselect.component.css']
})

export class LocationSelectComponent implements OnInit {

  formReport: FormGroup;
  subRef$: Subscription;
  r_gridregs: 0;
  public companyId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  Columns: string[] = [];
  DataGrid: IlocationslistResponse[] = [];
  dataList = null;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList = new MatTableDataSource;
  menuPop: IuserPermissionsResponse[] = [];
  menuPopList = new MatTableDataSource;
  filtermenu = { type: 'G' };
  cccode: string = "";
  countingid: number = 0;
  v_function: string = "";
  _currentId: number = 0;

  constructor(
    private router: Router,
    private dataService: DataService,
    private ccService: CcService,
    private countService: CountService,
    //private securityService: SecurityService,
    private routeparent: RouterService,
    private locationservice: LocationService,
    private permissionService: PermissionsService,
    private _snackBar: MatSnackBar
  ) {
    this.Columns = ['code', 'name', 'countingnumber', 'statusname', 'b_cnt', 'b_menu'];
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    if (this.countService.countingID == 0) this.Abort();
    this.cccode = this.ccService.cccode;
    this.countingid = this.countService.countingID;
    this.mensaje = "";
    //permisos
    this.Permissions = [];
    this.MenuG = [];
    this.v_function = (this.countService.moduleCode == "ARION" ? "COUNT-SC" : "COUNT-GS");
    this.permissionService.getPermission(this.v_function).subscribe(results => {
      if (results.body.length == 0) {
        this.mensaje = "No tiene permisos";
      }
      for (const entry of results.body) {
        this.addPermission(entry);// { id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      var _OptionCount = this.Permissions.find(x => x.code == "O" && x.type == "R");
      if (_OptionCount == null ) {
        this.Columns.splice(4,1);
      }

    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
    this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
    this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
    this.menuPop.push({ id: 99, function_code: 'REPORT', code: "R", description: "CONSULTAR", type: "R", icon: "manage_search", link: "locreport", toolTip: "Consultar todos los conteos" });
    this.menuPopList = new MatTableDataSource<IuserPermissionsResponse>(this.menuPop);
    //locations
    const localist: locationslist = {
      cc_id: this.ccService.ccID,
      type:"L"
    };
    this.mensaje = "";
    this.DataGrid = [];
    this.r_gridregs = 0;
    this.locationservice.List(localist).subscribe(results => {
      if (! results.body.isValid)
        this.openSB("No hay datos");
      for (const entry of results.body.resultData) {
        this.addData({ id: entry.id, code: entry.code, name: entry.name, status: entry.status, statusname: entry.statusname, cccode: entry.cccode, countingnumber:entry.countingnumber,countingtype:entry.countingtype });
      }
      this.dataList = new MatTableDataSource<IlocationslistResponse>(this.DataGrid);
      this.r_gridregs = this.dataList.data.length;
    },
      (e) => {
        this.openSB(e.error);
        console.log(e);
      });

  }


  addData(data) {
      this.DataGrid.push(data);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    switch (permission.type)
    {
      case "G":
        this.MenuG.push(permission);
        break;
      case "R":
        switch (permission.code) {
          case "U": 
          case "N": {
            this.menuPop.push(permission);
            break;
          }
          case "O": {
            //this.Columns.push("b_cnt");
            break;
          }
        }
        break;
    }
  }

  b_CurrentId(p_Id: number) {
    this._currentId = p_Id;
  }

  //menu execute items
  b_execute(prmCode: string, PLink: string) {
    var _Location = this.DataGrid.find(x => x.id == this._currentId);
    switch (prmCode) {
      case "N": {
        this.ChangeCount(PLink, _Location.id, _Location.name, _Location.countingnumber, _Location.countingtype)
        break;
      }
      // case "O" included in browser
      case "U": {
        this.DifferenceBetweenCounts(PLink, _Location.id, _Location.name, _Location.countingnumber, _Location.countingtype)
        break;
      }
      case "R": {
        this.Report(PLink, _Location.id, _Location.name, _Location.countingnumber, _Location.countingtype)
        break;
      }
    }
  }


  b_cnt(id,name,countingnumber,countingtype) {
    this.locationservice.putValues(id, name, countingnumber, countingtype)
    this.router.navigate(['tocount']);
  }

  Report(link,id, name, countingnumber, countingtype) {
    this.locationservice.putValues(id, name, countingnumber, countingtype)
    this.router.navigate([link]);
  }

  DifferenceBetweenCounts(link, id, name, countingnumber, countingtype) {
    if (countingnumber > 1)
    {
      this.locationservice.putValues(id, name, countingnumber, countingtype)
      this.router.navigate([link]);
    }
    else
    {
      this.openSB("No se ha cambiado de # de conteo");
    }
  }

  ChangeCount(link, id, name, countingnumber, countingtype) {
    this.locationservice.putValues(id, name, countingnumber, countingtype)
    this.router.navigate([link]);
  }

  b_gen(link, id, code) {
    if (code == "A") {
      let _Link = link + "/" + this.countingid.toString();
      this.router.navigate([_Link]);
    }
    else {
      this.router.navigate([link]);

    }
  }

  b_back() {
    if (this.countService.moduleCode == "ARION" ) 
    this.router.navigate(['ccselect']);
    else
      this.router.navigate(['ccselectgs']);

  }

  Abort() {
    if (this.countService.moduleCode == "ARION")
      this.router.navigate(['ccselect']);
    else
      this.router.navigate(['ccselectgs']);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
