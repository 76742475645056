import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICountStatusCC } from '../app/Interfaces/icc';
import { count, countingnumber, countpending, differences, differencesbnc, iGetTotalCount, RDifferences, RDifferencesBNC } from '../app/Interfaces/icount';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { locreport, pascount, RCountLocation } from '../app/Interfaces/ipas';
import { GRReport } from '../app/Interfaces/iReport';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class CountService {
  private storage: any;

  countingID: number = 0;
  moduleCode: string;
  private baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  public putValues(id, modulecode) {
    this.countingID = id;
    this.moduleCode = modulecode;
  }

  public GetDifferences(Data: differences) {
    try {
      let url = this.baseUrl + 'api/count/differences/';
      return this.dataService.post<RDifferences>(url,Data).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  public DifferencesBnc(Data: differencesbnc) {
    try {
      let url = this.baseUrl + 'api/count/differencesbnc/';
      return this.dataService.post<RDifferencesBNC>(url, Data)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  public CountingNumber(Data: countingnumber) {
    try {
      let url = this.baseUrl + 'api/count/countingnumber/';
      return this.dataService.put<IgeneralResponse03>(url, Data)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  public CountLocation(Data: locreport) {
    try {
      let url = this.baseUrl + 'api/count/location/';
      return this.dataService.post<RCountLocation>(url, Data)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  public CountStatus(CompanyId: number) {
    try {
      let url = this.baseUrl + 'api/count/countstatus/';
      return this.dataService.get<ICountStatusCC>(url + CompanyId)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  public SaveLine(Data: pascount) {
    try {
      let url = this.baseUrl + 'api/count/';
      return this.dataService.post<IgeneralResponse03>(url, Data)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  public SumLinesPending(PCountingId: number) {
    try {
      let url = this.baseUrl + 'api/count/v1/sumlinespending/';
      return this.dataService.get<IgeneralResponse03>(url + PCountingId)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  public UpdatePendingCount(PPCount: countpending) {
    try {
      let url = this.baseUrl + 'api/count/pending/';
      return this.dataService.post<IgeneralResponse03>(url, PPCount)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  public GetDifferencesBetweenCounts(PCount: countpending) {
    try {
      let url = this.baseUrl + 'api/count/differencesallbnc/';
      return this.dataService.post<IgeneralResponse03>(url, PCount).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  public CloseCountV2(PCount: count) {
    try {
      let url = this.baseUrl + 'api/count/closecountv2/';
      return this.dataService.put<IgeneralResponse03>(url, PCount)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  public GetTotalCount(PGTC: iGetTotalCount) {
    try {
      let url = this.baseUrl + 'api/count/v1/totalcount/';
      return this.dataService.post<GRReport>(url, PGTC)
        .pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }
}
