import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PrdLocationService } from '../../../../services/prdLocation.service';
import { SecurityService } from '../../../../services/security.service';
import { ErrorStateMatcher1 } from '../../../error-state-matcher1';
import { IAddLocationProduction } from '../../../Interfaces/ilocation';

@Component({
  selector: 'app-editlocationproduction',
  templateUrl: './editlocationproduction.component.html',
})

export class EditLocationProductionComponent implements OnInit {
  formedit: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";

  constructor(
    private formBuilder: FormBuilder, 
    private securityService: SecurityService,
    private router: Router,
    //private locationService:LocationService,
    private prdLocationService: PrdLocationService,
    private dialogRef: MatDialogRef<EditLocationProductionComponent>,
    private _snackBar: MatSnackBar
  )
  {
    this.formedit = formBuilder.group({
      registryTimeout: [0, [Validators.required, Validators.min(1), Validators.max(60)]],
print:[false, [Validators.required]]
    })
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    this.mensaje = "";
    this.prdLocationService.GetProduction(this.prdLocationService.locationID).subscribe(results => {
      if (results.body.isValid) {
        this.formedit.get('registryTimeout').setValue(results.body.resultData.registryTimeout);
        this.formedit.get('print').setValue(results.body.resultData.print)
      }
      else {
        alert(results.body.error.description);
      }
    })
  }


  Cancel() {
    this.dialogRef.close();
  }

  Save()
  {
    try {
      const IReg: IAddLocationProduction =
      {
        id:this.prdLocationService.locationID,
        registryTimeout: this.formedit.value.registryTimeout,
print:this.formedit.value.print
      };
      this.prdLocationService.EditProduction(IReg).subscribe(results => {
        if (results.body.isValid)
        {
          this.openSB("Ubicación configurada");
          this.dialogRef.close(true);
        }
        else
        {
          alert(results.body.error.description);
        }
      },
        (e) => {
          this.mensaje = e.error;
          console.log(e);
        }
      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  Delete() {
    try {
      this.prdLocationService.DeleteProduction(this.prdLocationService.locationID).subscribe(results => {
        if (results.body.isValid) {
          this.openSB(results.body.resultData.description);
          this.dialogRef.close(true);
        }
        else {
          alert(results.body.error.description);
        }
      },
        (e) => {
          this.mensaje = e.error;
          console.log(e);
        }
      );
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formedit.get(nombreControl);
    return control.hasError(validacion);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  
}
