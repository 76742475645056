import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BackgroundProcessService } from '../../../../services/backgroundprocess.service';
import { CcService } from '../../../../services/cc.service';
import { CountService } from '../../../../services/count.service';
import { DataService } from '../../../../services/data.service';
import { SecurityService } from '../../../../services/security.service';
import { ErrorStateMatcher1 } from '../../../error-state-matcher1';
import { count, countpending } from '../../../Interfaces/icount';

@Component({
  selector: 'app-closecount',
  templateUrl: './closecount.component.html'
})

export class ClosecountComponent implements OnInit {
  formadd: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  msgtype: string;
  v_ccid:number;
  r_ccregs: 0;

  constructor(
    private formBuilder: FormBuilder, 
    private dataService: DataService,
    private countService: CountService,
    private ccService: CcService,
    private securityService: SecurityService,
    private router: Router,
    private _snackBar: MatSnackBar,
private backgroundProcess: BackgroundProcessService
  ) {
    this.formadd = formBuilder.group({
      type: [null, Validators.required],
    });
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    if (this.countService.countingID == 0) this.Abort();
    this.mensaje = "Al confirmar el cierre del conteo las cantidades ingresadas se tomarán como el inventario actual y se registrará la transaccion en el kardex.";
    this.msgtype = "msg-notify"
    //save pending count
    const cp: countpending = {
      cc_id: this.ccService.ccID,
      counting_id: this.countService.countingID,
      user_id: 0
    };
    this.backgroundProcess.GetStatus("pendingcount").subscribe(result => {
      if (!result.body.isValid) {
        this.countService.UpdatePendingCount(cp).subscribe(results => {
          if (results.body.isValid) {
            console.log("Conteo actualizado");
          }
          else {
            if (results.body.error.code != -1)
              alert(results.body.error.description);
          }
        },
          (e) => {
            console.log(e);
          });
      }
      (e) => {
        console.log(e);
      }
    });
  }


  Accept() {
    this.countService.SumLinesPending(this.countService.countingID).subscribe(result => {
      if (result.body.isValid) {
        this.openSB(result.body.resultData.description);
        return;
      }
      else {
        let v_differences = false;
        const icc: count = {
          ccId: this.ccService.ccID, countingId: this.countService.countingID
        }
        const iccp: countpending = {
          counting_id: this.countService.countingID,
          cc_id: this.ccService.ccID,
          user_id: 0
        };
        try {
          this.countService.GetDifferencesBetweenCounts(iccp).subscribe(results => {
            if (results.body.isValid) {
              //this  block in observation
              if (results.body.resultData.id > 0) {
                v_differences = false;
                this.openSB(results.body.resultData.description);
              }
              else {
                try {
                  this.countService.CloseCountV2(icc).subscribe(results => {
                    if (results.body.isValid) {
                      this.openSB(results.body.resultData.description);
                      this.router.navigate(['inventory']);
                    }
                    else { alert(results.body.error.description); }
                  },
                    (e) => {
                      alert(e.error.errors.description);
                    }
                  );
                }
                catch (e) {
                  console.log(e.message);
                  alert(e.message);
                }

              }
            }
            else { alert(results.body.error.description); }
          },
            (e) => {
              alert(e.error.errors.description);
            }
          );
        }
        catch (e) {
          console.log(e.message);
          alert(e.message);
        }
      }
      (e) => {
        alert(e.error.errors.description);
      }
    });

  }

  Cancel()
  {
          this.router.navigate(['locationselect']);
  }

  Abort() {
    this.router.navigate(['ccselect']);
  }

  openSB(msg) {
    this._snackBar.open(msg,'', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }


}
