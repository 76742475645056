import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Get_configV2, GRConfigurationByCode, IGetConfigurationCodeV3, Put_config } from '../app/Interfaces/iconfig';
import { IgeneralResponse03, IgrconfigResponseV2 } from '../app/Interfaces/igeneralresponse02';
import { IGRThemes } from '../app/Interfaces/ithemes';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class SystemInternalService {
  private storage: any;

  baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  SaveConfigDB() {
    try {
      let url = this.baseUrl + 'api/v1/configdb/';
      return this.dataService.put<IgeneralResponse03>(url, 0).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  SaveConfiguration(CfgData: Put_config[]) {
    try {
      let url = this.baseUrl + 'api/configuration';
      return this.dataService.put<IgeneralResponse03>(url, CfgData).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  GetTheme(PApplyTo: string) {
    try {
      let url = this.baseUrl + 'api/systeminternal/v1/themes/';
      return this.dataService.get<IGRThemes>(url+PApplyTo).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  GetConfigurationV2(PGet: Get_configV2)
  {
    try {
      let url = this.baseUrl + 'api/configuration/v2';
      return this.dataService.post<IgrconfigResponseV2>(url,PGet).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }

  GetConfiguration(PCode: IGetConfigurationCodeV3) {
    try {
      let url = this.baseUrl + 'api/configuration/v3/code/';
      return this.dataService.post<GRConfigurationByCode>(url, PCode).
        pipe(res => { return res });
    }
    catch (e) {
      console.log("Error");
    }
  }
}
