///<reference path="../../../services/data.service.ts" />
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CcService } from '../../../services/cc.service';
import { CompanyService } from '../../../services/company.service';
import { DataService } from '../../../services/data.service';
import { RouterService } from '../../../services/router.service';
import { SecurityService } from '../../../services/security.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { IccResponse } from '../../Interfaces/icc';
import { IgeneralResponse02 } from '../../Interfaces/igeneralresponse02';
import { IuserPermissionsResponse } from '../../Interfaces/iuser';


@Component({
  selector: 'app-cclist',
  templateUrl: './cclist.component.html',
  styleUrls: ['./cclist.component.css']
})

export class CcListComponent implements OnInit {


  subRef$: Subscription;
  r_ccregs: 0;
  public companyId: string;
  companyname: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  Cc: IccResponse[] = [];
  ccList = null;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList =new MatTableDataSource;
  filtermenu = { type: 'G' };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private securityService: SecurityService,
    private companyservice: CompanyService,
    private routeparent: RouterService,
    private ccservice: CcService,
    private _snackBar: MatSnackBar,
    private location: Location
  ) {
    this.Columns = ['code', 'name'];
  }
  selectedvalue: number; selectedview: string;

  ngOnInit() {
    this.mensaje = "";
    this.companyname = this.companyservice.companyName;
    //permisos
    let url = environment.urlAPI + 'api/user/permission/';
    this.Permissions = [];
    this.MenuG = [];
    this.dataService.get<IuserPermissionsResponse[]>(url + "CC").subscribe(results => {
      if (results.body.length == 0)
        this.mensaje = "No tiene permisos";
      for (const entry of results.body) {
        this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      //this.addPermission({ id: -1, function_code: "ATRAS", code: "ATRAS", description: "ATRAS", type: "G", icon: "arrow_back_ios", link: this.routeparent.routerParent });
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource < IuserPermissionsResponse >(this.MenuG);
    },
      (e) => {
        this.mensaje  =  e.error;
        console.log(e);
      });
    //cc
    this.Cc= [];
    this.r_ccregs = 0;
    this.ccservice.GetCCListV4(this.companyservice.companyID).subscribe(results => {
      if (!results.body.isValid)
        this.mensaje = "No hay datos";
      for (const entry of results.body.resultData) {
        this.addCc({ id: entry.id,idparent:entry.idparent, name: entry.name,code: entry.code,type: entry.type});
      }
      this.ccList = new MatTableDataSource<IccResponse>(this.Cc);
      this.r_ccregs = this.ccList.data.length;
    },
      (e) => {
        this.mensaje = e.error;
        alert(e.error);
        console.log(e);
      });
  }

  addCc(cc) {
      this.Cc.push(cc);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    if (permission.type == "G") {
      switch (permission.code) {
        case "A": {
          if (this.companyservice.userList)
            this.MenuG.push(permission);
          break;
        }
        default: {
          this.MenuG.push(permission);
          break;
        }
      }
    }
    else {
      switch (permission.code) {
        case "M": {
          this.Columns.push("b_mod");
          break;
        }
        case "B": {
          this.Columns.push("b_del");
          break;
        }
      }
    }
  }

  b_mod(id,name,code) {
    this.ccservice.putValues(id,name,code)
    this.router.navigate(['/ccedit']);
  }

  b_del(id, name, code) {
    this.ccservice.putValues(id, name, code)
      this.dataService.delete<IgeneralResponse02>(environment.urlAPI + 'api/cc/v1/' +id).subscribe(results => {
        if (results.body.isValid) {

          this.openSB("CC Eliminado");
          this.location.back();
          //this.router.navigate(['cclist']);

        }
        else
          alert(results.body.errors.description);
    },
      (e) => {
        this.mensaje = e.error;
        alert(e.error);
        console.log(e);
      });
  }

  b_gen(link, id) {
        this.router.navigate([link]);
  }

  b_back() {
   // this.location.back();
    this.router.navigate([this.routeparent.routerParent01]);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
