import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppService {

  name: string = "";
  currentPage: number = 0;
  //---
  ccId: number = 0;
  groupId: number = 0;
  pasName: string = '';

  constructor() { }

  public PASOptionsGroupPutValues(Name: string,CurrentPage: number) {
    this.name = Name;
    this.currentPage = CurrentPage;
  }

  public PASListPutValues(CCId: number, GroupId: number, PASName: string, CurrentPage: number) {
    this.ccId = CCId;
    this.groupId = GroupId;
    this.pasName = PASName;
    this.currentPage = CurrentPage;
  }

}

