import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IgeneralResponse03 } from '../app/Interfaces/igeneralresponse02';
import { IGRDataProduction, IPutDataProduction } from '../app/Interfaces/ipas';
import { IGRPASOptions, IRPASOptionsGroup, IRPASOptionsGroupSelect } from '../app/Interfaces/iproduction';
import { environment } from '../environments/environment';
import { DataService } from './data.service';

@Injectable()
export class PASPrdService {
  private storage: any;
  private baseUrl = environment.urlAPI;

  constructor(private http: HttpClient, private dataService: DataService) { }

  public GetPASOptionsGroupSelect(PASId: number) {
    try {
      let url = this.baseUrl + 'api/pasprd/v1/optionsgroupselect/';
      return this.dataService.get<IRPASOptionsGroupSelect>(url +PASId).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  public GetPASOptionsGroup(PTextOption: string) {
    try {
      let url = this.baseUrl + 'api/pasprd/v1/pasoptionsgroup/';
      if (PTextOption.length == 0) PTextOption = "*";
      return this.dataService.get <IRPASOptionsGroup>(url + PTextOption.trim()).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  public GetGroupOptions(GroupId: number) {
    try {
      let url = this.baseUrl + 'api/pasprd/v1/pasoptions/';
      return this.dataService.get<IGRPASOptions>(url +GroupId).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e.message);
    }
  }

  //Delete data production in CC
  public DeleteDataProduction(PId: number) {
    let url = environment.urlAPI + 'api/pasprd/v1/dataproduction/';
    try {
      return this.dataService.delete<IgeneralResponse03>(url + PId).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  //get production data 
  public DataProduction(PCCId: number, PPASId: number) {
    let url = environment.urlAPI + 'api/pasprd/v1/dataproduction/';
    try {
      return this.dataService.get<IGRDataProduction>(url + PCCId + "/" + PPASId).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  //Put data production in CC
  public PutDataProduction(PDataProduction: IPutDataProduction) {
    let url = environment.urlAPI + 'api/pasprd/v1/dataproduction/';
    try {
      return this.dataService.put<IgeneralResponse03>(url, PDataProduction).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

  //add data production in CC
  public AddDataProduction(PDataProduction: IPutDataProduction) {
    let url = environment.urlAPI + 'api/pasprd/v1/dataproduction/';
    try {
      return this.dataService.post<IgeneralResponse03>(url, PDataProduction).
        pipe(res => { return res });
    }
    catch (e) {
      console.log(e);
    }
  }

}
